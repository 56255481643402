import React, {Component} from "react";
import SupporterImage from "../style/img/supporter_image.png";
import Chart from "chart.js";

class DailyReportPopup extends Component{
    constructor(props){
        super(props);
        this.state = {
            goodRate: "-",
            averageRate: "-",
            badRate: "-",
            closedConversations: "-",
            openConversations: "-",
            outbox: "-",
            inbox: "-",
            totalUsers: "-",
            chartDataSet: []
        };
    }
    componentDidMount(){
        this.getSupporterDailyReport();
        this.getChartData();
    }
    toPersianDigits(number){
        let id= ['۰','۱','۲','۳','۴','۵','۶','۷','۸','۹'];
        return number.replace(/[0-9]/g, function(w){
            return id[+w]
        });
    }
    getSupporterDailyReport = () => {
        const axios = require('axios');
        let data = {
            "username": sessionStorage.getItem("user"),
            "password": sessionStorage.getItem("tk")
        };
        axios.post(process.env.REACT_APP_RINGEL_URL + "supp/getsupporterdailyreport/"+ this.props.supporter.token +"/", data, {contentType: 'application/json'})
            .then((response)=>{
                console.log(response);
                this.setState({
                    averageRate: response.data.averageRate,
                    badRate: response.data.badRate,
                    goodRate: response.data.goodRate,
                    closedConversations: response.data.closeConversationCount,
                    openConversations: response.data.openConversationCount,
                    outbox: response.data.outboxNumber,
                    inbox: response.data.inboxNumber,
                    totalUsers: response.data.connectedUsers
                });
            })
            .catch((error)=>{
                console.log(error)
            });
    }
    getChartData = () => {
        const axios = require('axios');
        let data = {
            "username": sessionStorage.getItem("user"),
            "password": sessionStorage.getItem("tk")
        };
        axios.post(process.env.REACT_APP_RINGEL_URL + "supp/getsupportersreport/message/24h/"+
            this.props.supporter.token+"/0/0/outbox/", data, {contentType: 'application/json'})
            .then((response)=>{
                console.log(response);
                let outboxQuantity = [];
                for(let i=0; i<response.data.length; i++){
                    outboxQuantity.push(response.data[i].count);
                }
                let dataSet = {
                    label: 'پیام‌های ارسالی',
                    fill: false,
                    borderColor: '#2196f3',
                    data: outboxQuantity
                };
                this.setState(prevState => ({
                    chartDataSet: [...prevState.chartDataSet ,dataSet]
                }),()=>{
                    this.drawChart(this.state.chartDataSet);
                });
            })
            .catch((error)=>{
                console.log(error);
            });
        axios.post(process.env.REACT_APP_RINGEL_URL + "supp/getsupportersreport/message/24h/"+this.props.supporter.token+"/0/0/inbox/", data, {contentType: 'application/json'})
            .then((response)=>{
                console.log(response);
                let inboxQuantity = [];
                for(let i=0; i<response.data.length; i++){
                    inboxQuantity.push(response.data[i].count);
                }
                let dataSet = {
                    label: 'پیام‌های دریافتی',
                    fill: false,
                    borderColor: '#ff9800',
                    data: inboxQuantity
                };
                this.setState(prevState => ({
                    chartDataSet: [...prevState.chartDataSet ,dataSet]
                }),()=>{
                    this.drawChart(this.state.chartDataSet);
                });
            })
            .catch((error)=>{
                console.log(error);
            });
    }

    drawChart = dataSet => {
        Chart.defaults.global.defaultFontFamily = 'IRANSansLight';
        // let ctx = document.getElementById('dailyReportChart').getContext('2d');
        // let chart = new Chart(ctx, {
        //     type: 'line',
        //     data: {
        //         labels: [
        //             '۰۰:۰۰',
        //             '۰۱:۰۰',
        //             '۰۲:۰۰',
        //             '۰۳:۰۰',
        //             '۰۴:۰۰',
        //             '۰۵:۰۰',
        //             '۰۶:۰۰',
        //             '۰۷:۰۰',
        //             '۰۸:۰۰',
        //             '۰۹:۰۰',
        //             '۱۰:۰۰',
        //             '۱۱:۰۰',
        //             '۱۲:۰۰',
        //             '۱۳:۰۰',
        //             '۱۴:۰۰',
        //             '۱۵:۰۰',
        //             '۱۶:۰۰',
        //             '۱۷:۰۰',
        //             '۱۸:۰۰',
        //             '۱۹:۰۰',
        //             '۲۰:۰۰',
        //             '۲۱:۰۰',
        //             '۲۲:۰۰',
        //             '۲۳:۰۰'
        //         ],
        //         datasets: dataSet
        //     },
        //     options: {}
        // });
    };
    render(){
        return(
            <React.Fragment>
                <div className="supporter-daily-report-popup-wrapper">
                    <div className="supporter-daily-report-popup">
                        <div className="row no-gutters h-100 position-relative">
                            <div className="col-4" style={{backgroundColor: "#f7f7f7", padding: "0 24px"}}>
                                <div className="report-popup-supporter-info">
                                    <div className="user-image" style={{marginLeft: 0}}>
                                        <img src={SupporterImage} alt="User"/>
                                    </div>
                                    <div className="supporter-name">{this.props.supporter.name}</div>
                                    <div className="supporter-role">{this.props.supporter.supporterType}</div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-6">
                                        <div className="report-popup-box">
                                            <div className="report-popup-box-title">کل گفتگوها</div>
                                            <div className="report-popup-box-value">
                                                {this.toPersianDigits((this.state.closedConversations + this.state.openConversations).toString())}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="report-popup-box">
                                            <div className="report-popup-box-title">کل کاربران</div>
                                            <div className="report-popup-box-value">
                                                {this.toPersianDigits(this.state.totalUsers.toString())}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-12">
                                        <div className="report-popup-box">
                                            <div className="row no-gutters w-100 h-100">
                                                <div className="col-4 d-flex align-items-center">
                                                    <div className="report-popup-box-title">امتیازات نظرسنجی</div>
                                                </div>
                                                <div className="col-8">
                                                    <div className="row no-gutters w-100 h-100">
                                                        <div className="col-4 d-flex flex-column justify-content-between">
                                                            <div className="report-popup-box-title">خوب</div>
                                                            <div className="report-popup-box-value">
                                                                {this.toPersianDigits(this.state.goodRate.toString())}
                                                            </div>
                                                        </div>
                                                        <div className="col-4 d-flex flex-column justify-content-between">
                                                            <div className="report-popup-box-title">متوسط</div>
                                                            <div className="report-popup-box-value">
                                                                {this.toPersianDigits(this.state.averageRate.toString())}
                                                            </div>
                                                        </div>
                                                        <div className="col-4 d-flex flex-column justify-content-between">
                                                            <div className="report-popup-box-title">ضعیف</div>
                                                            <div className="report-popup-box-value">
                                                                {this.toPersianDigits(this.state.badRate.toString())}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-6">
                                        <div className="report-popup-box">
                                            <div className="report-popup-box-title">گفتگو باز</div>
                                            <div className="report-popup-box-value">
                                                {this.toPersianDigits(this.state.openConversations.toString())}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="report-popup-box">
                                            <div className="report-popup-box-title">گفتگو بسته</div>
                                            <div className="report-popup-box-value">
                                                {this.toPersianDigits(this.state.closedConversations.toString())}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-6">
                                        <div className="report-popup-box">
                                            <div className="report-popup-box-title">پیام ارسالی</div>
                                            <div className="report-popup-box-value">{this.toPersianDigits(this.state.outbox.toString())}</div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="report-popup-box">
                                            <div className="report-popup-box-title">پیام دریافتی</div>
                                            <div className="report-popup-box-value">{this.toPersianDigits(this.state.inbox.toString())}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-8 d-flex justify-content-center align-items-center">
                                <canvas id="dailyReportChart"></canvas>
                            </div>
                            <div className="popup-close-btn fal fa-times" onClick={this.props.closePopup}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default DailyReportPopup;