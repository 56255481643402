import React, { Component } from 'react';

class OfflineSupporter extends Component {
    state = {
        textareaDisabled: true,
        offlineTextareaLength: sessionStorage.getItem('message') ? sessionStorage.getItem('message').length : 0,
        errorMessage: ""
    }

    messageEditSubmit = () => {
        let message = document.getElementsByClassName('offline-textarea')[0].value;
        if (message === "") this.setState({errorMessage: "متن پیام خودکار نمی تواند خالی باشد"});
        else {
            sessionStorage.setItem('message', message);
            this.props.onClick(message);
            this.setState({textareaDisabled: true});
        }
    }

    messageEditCancel = () => {
        document.getElementsByClassName('offline-textarea')[0].value = sessionStorage.getItem('message');
        this.setState({textareaDisabled: true, offlineTextareaLength: sessionStorage.getItem('message').length});
    }

    textareaTypeHandle = e => {
        this.setState({offlineTextareaLength: e.target.value.length, errorMessage: ""});
    }

    toOnlineClick = () => {
        this.props.onClick();
    }

    render() { 
        const {textareaDisabled, offlineTextareaLength, errorMessage} = this.state;
        return (
            <React.Fragment>
                <div className="container-fluid offline-root h-100">
                    <div className="container h-100 offline-container" style={{maxWidth: "1070px"}}>
                        <div className="row h-100">
                            <div className="col-sm-0 col-md-3 galaxy-left h-100" />
                            <div className="col-sm-12 col-md-6 h-100">
                                <div className="offline-title">در دسترس نیستم</div>
                                <div className="offline-subTitle">
                                در این وضعیت، پیام های مراجعین شما دریافت و ذخیره خواهد شد. برای  مشاهده پیام‌ها و پاسخگویی به آنها، باید وضعیت خود را به وضعیت «آنلاین» تغییر دهید.
                                </div>
                                <div style={{textAlign: "right", width: "447px"}}>
                                    <label className="offline-label" htmlFor="offline-textarea">
                                        در صورت نیاز می‌توانید پیام خودکار را ویرایش کنید
                                    </label>
                                    <textarea
                                        maxLength="1000"
                                        disabled={textareaDisabled}
                                        className="offline-textarea"
                                        onKeyUp={this.textareaTypeHandle}
                                        defaultValue={sessionStorage.getItem('message')}
                                    />
                                    <hr className="offline-textarea-hr" />
                                    <div className="offline-textarea-bottom">
                                    {textareaDisabled ? (
                                        <button type="button" className="offline-edit-button" onClick={() => this.setState({textareaDisabled: false})} >
                                            <span className="fal fa-edit" />
                                            ویرایش
                                        </button>
                                    ):(
                                        <React.Fragment>
                                            <div style={{display: "flex"}}>
                                                <button className="offline-textarea-submit" onClick={this.messageEditSubmit}>
                                                <span className="fal fa-check" />
                                                ثبت
                                                </button>
                                                <button className="offline-textarea-cancel" onClick={this.messageEditCancel}>
                                                    لغو
                                                </button>
                                            </div>
                                            <div className="offline-textarea-counter">{offlineTextareaLength} از 1000</div>
                                        </React.Fragment>
                                    )}
                                    </div>
                                </div>
                                <div
                                    className="offline-error-message"
                                    style={{opacity: errorMessage === '' ? 0 : '1', marginTop: "8px", position: "relative", textAlign: "right", top: "-86px"}}
                                >
                                    {errorMessage}
                                </div>
                                <div className="offline-subTitle" style={{position: "relative", top: "-40px"}}>
                                جهت ورود به پنل و ارتباط با مراجعه کنندگان وضعیت خود را به وضعیت «آنلاین» تغییر دهید
                                </div>
                                <div className="offline-buttons">
                                    <button className="offline-exit-button" onClick={() => window.location.reload()}>
                                    خروج از پنل
                                    </button>
                                    <button className="offline-submit-button" onClick={this.toOnlineClick}>
                                    تغییر به وضعیت آنلاین
                                    </button>
                                </div>
                            </div>
                            <div className="col-sm-0 col-md-3 galaxy-right h-100" />
                        </div>
                    </div>
                </div> 
            </React.Fragment>
        );
    }
}
 
export default OfflineSupporter;