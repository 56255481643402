import React, { Component } from 'react';
import Chart from 'chart.js';
import PersianDate from 'persian-date';

import 'react-persian-calendar-date-picker/lib/DatePicker.css';
import DatePicker from 'react-persian-calendar-date-picker';

class ChartComponent extends Component {
  constructor(props) {
    super(props);
    this.chart = null;
    this.state = {
      chartDataSet: [],
      supporterList: [],
      selectedStartDate: null,
      selectedEndDate: null,
      startDateTimestamp: 0,
      endDateTimestamp: 0,
      supporterName: 'total'
    };
    this.getChartData = this.getChartData.bind(this);
    this.drawChart = this.drawChart.bind(this);
    this.getAllSupporters = this.getAllSupporters.bind(this);
    this.updateStartDate = this.updateStartDate.bind(this);
    this.updateEndDate = this.updateEndDate.bind(this);
    this.refreshChart = this.refreshChart.bind(this);
    this.updateSupporter = this.updateSupporter.bind(this);
  }
  componentDidMount() {
    this.getChartData();
    this.getAllSupporters();
  }
  getAllSupporters() {
    const axios = require('axios');
    let data = {
      username: sessionStorage.getItem('user'),
      password: sessionStorage.getItem('tk')
    };
    axios
      .post(process.env.REACT_APP_RINGEL_URL + 'supp/getall/', data, {
        contentType: 'application/json'
      })
      .then(response => {
        console.log(response.data);
        this.setState({
          supporterList: response.data
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
  drawChart(data‌Set) {
    Chart.defaults.global.defaultFontFamily = 'IRANSansLight';
    let ctx = document.getElementById('myChart').getContext('2d');
    if (this.chart !== null && this.chart !== '') {
      this.chart.destroy();
    }
    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: [
          '۰۰:۰۰',
          '۰۱:۰۰',
          '۰۲:۰۰',
          '۰۳:۰۰',
          '۰۴:۰۰',
          '۰۵:۰۰',
          '۰۶:۰۰',
          '۰۷:۰۰',
          '۰۸:۰۰',
          '۰۹:۰۰',
          '۱۰:۰۰',
          '۱۱:۰۰',
          '۱۲:۰۰',
          '۱۳:۰۰',
          '۱۴:۰۰',
          '۱۵:۰۰',
          '۱۶:۰۰',
          '۱۷:۰۰',
          '۱۸:۰۰',
          '۱۹:۰۰',
          '۲۰:۰۰',
          '۲۱:۰۰',
          '۲۲:۰۰',
          '۲۳:۰۰'
        ],
        datasets: data‌Set
      },
      options: {}
    });
  }
  getChartData() {
    const axios = require('axios');
    let data = {
      username: sessionStorage.getItem('user'),
      password: sessionStorage.getItem('tk')
    };
    axios
      .post(
        process.env.REACT_APP_RINGEL_URL + 'supp/getsupportersreport/message/24h/' +
          this.state.supporterName +
          '/' +
          this.state.startDateTimestamp +
          '/' +
          this.state.endDateTimestamp +
          '/outbox/',
        data,
        { contentType: 'application/json' }
      )
      .then(response => {
        console.log(response);
        let outboxQuantity = [];
        for (let i = 0; i < response.data.length; i++) {
          outboxQuantity.push(response.data[i].count);
        }
        let dataSet = {
          label: 'پیام‌های ارسالی',
          fill: false,
          borderColor: '#2196f3',
          data: outboxQuantity
        };
        this.setState(
          prevState => ({
            chartDataSet: [...prevState.chartDataSet, dataSet]
          }),
          () => {
            this.drawChart(this.state.chartDataSet);
          }
        );
      })
      .catch(error => {
        console.log(error);
      });
    axios
      .post(
        process.env.REACT_APP_RINGEL_URL + 'supp/getsupportersreport/message/24h/' +
          this.state.supporterName +
          '/' +
          this.state.startDateTimestamp +
          '/' +
          this.state.endDateTimestamp +
          '/inbox/',
        data,
        { contentType: 'application/json' }
      )
      .then(response => {
        console.log(response);
        let inboxQuantity = [];
        for (let i = 0; i < response.data.length; i++) {
          inboxQuantity.push(response.data[i].count);
        }
        let dataSet = {
          label: 'پیام‌های دریافتی',
          fill: false,
          borderColor: '#ff9800',
          data: inboxQuantity
        };
        this.setState(
          prevState => ({
            chartDataSet: [...prevState.chartDataSet, dataSet]
          }),
          () => {
            this.drawChart(this.state.chartDataSet);
          }
        );
      })
      .catch(error => {
        console.log(error);
      });
  }
  updateStartDate(date) {
    console.log(date);
    let newDateFormat = new PersianDate([date.year, date.month, date.day]);
    console.log(newDateFormat.valueOf());
    this.setState({
      selectedStartDate: date,
      startDateTimestamp: newDateFormat.valueOf()
    });
  }
  updateEndDate(date) {
    console.log(date);
    let newDateFormat = new PersianDate([date.year, date.month, date.day]);
    console.log(newDateFormat.valueOf());
    this.setState({
      selectedEndDate: date,
      endDateTimestamp: newDateFormat.valueOf()
    });
  }
  refreshChart() {
    // this.chart.data.labels.pop();
    this.chart.data.datasets = [];
    this.setState(
      {
        chartDataSet: []
      },
      () => {
        this.chart.update();
        this.getChartData();
      }
    );
  }
  updateSupporter(event) {
    this.setState({
      supporterName: event.target.value
    });
  }
  render() {
    return (
        <div
          className='row justify-content-center h-100 ltr'
          style={{
            backgroundColor: '#f5f1f5',
            'overflow-y': 'scroll'
          }}
        >
          <div className='col-lg-11 col-10 rtl' style={{ paddingTop: '20px' }}>
            <div className='chart-option-wrapper'>
              <select onChange={this.updateSupporter} style={{border:'solid 1px #ddd', 'borderRadius':'3px', color:'#888'}}>
                <option defaultValue='total'>همه ساپورترها</option>
                {this.state.supporterList.length !== 0
                  ? this.state.supporterList.map((supporter, index) => (
                      <option value={supporter.token} key={index}>
                        {supporter.name}
                      </option>
                    ))
                  : null}
              </select>
              <div className='date-picker-wrapper'>
                <span>از</span>
                <DatePicker
                  inputPlaceholder='انتخاب تاریخ شروع'
                  onChange={this.updateStartDate}
                  selectedDay={this.state.selectedStartDate}
                />
                ‌<span>تا</span>
                <DatePicker
                  inputPlaceholder='انتخاب تاریخ پایان'
                  onChange={this.updateEndDate}
                  selectedDay={this.state.selectedEndDate}
                />
                <div
                  className='chart-refresh-btn fal fa-sync'
                  onClick={this.refreshChart}
                ></div>
              </div>
            </div>
            <div className='chart-wrapper'>
              <canvas id='myChart'></canvas>
            </div>
          </div>
        </div>
    );
  }
}
export default ChartComponent;
