import React, { Component } from 'react';

class Modal extends Component {
    state = {modalTextareaLength: sessionStorage.getItem('message') !== null ? sessionStorage.getItem('message').length : 0, textereaErrorMessage: ""  }

    modalTextareaTypeHandle = e => {
        this.setState({modalTextareaLength: e.target.value.length, textereaErrorMessage: ""});
    }

    modalSubmitClick = () => {
        let message = document.getElementsByClassName('modal-textarea')[0].value;
        if(message === "") this.setState({textereaErrorMessage: "متن پیام خودکار نمی تواند خالی باشد"});
        else {
            sessionStorage.setItem('message', message);
            this.props.submitClick();
            this.fadeModal();
        }
    }

    fadeModal = () => {
        let body = document.getElementsByTagName('body')[0];
        let modal = document.getElementById('modal');
        let backdrop = document.getElementsByClassName('modal-backdrop')[0];
        modal.removeAttribute('aria-modal');
        modal.setAttribute('aria-hidden', "true");
        modal.style.display = "none";
        modal.classList = "modal fade";
        body.classList.remove('modal-open');
        backdrop.parentNode.removeChild(backdrop);
    }

    render() { 
        const {modalTextareaLength, textereaErrorMessage} = this.state;
        return (
            <div className="modal fade" id="modal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="modal-title">فعالسازی وضعیت «در دسترس نیستم»</div>
                                <div className="modal-subTitle">
                                    پس از فعالسازی این وضعیت، پیام‌های ارسالی از طرف مراجعین دریافت و ذخیره خواهد شد. شما می‌توانید پس از ورود مجدد به پنل، به مراجعین خود پاسخ دهید.
                                </div>
                                <div className="modal-paragraph">
                                لطفاً متن پیام خود را وارد نمایید تا در صورتی که مراجعه کننده‌ای به شما پیام داد، این پیام به صورت خودکار برای ایشان ارسال شود.
                                </div>
                                <textarea
                                className="modal-textarea"
                                maxLength="1000"
                                onKeyUp={this.modalTextareaTypeHandle}
                                defaultValue={sessionStorage.getItem('message')}
                                placeholder=
                                "متن پیام خود را اینجا بنویسید&#010;&#000;&#010;مثال: سلام عزیزم&#010;ببخشید من از ساعت 16 تا 20 کلاس زبان دارم. تو پیامت رو بنویس، من به محض این که برسم خونه میخونم و جواب میدم.البته اگه کارت خیلی واجبه بهم زنگ بزن..." 
                                />
                                <div className="modal-textarea-counter">{modalTextareaLength} از 1000</div>
                                <div className='login-error-box'
                                    style={{opacity: textereaErrorMessage === '' ? 0 : '1', marginTop: "8px", position: "relative", textAlign: "right", top: "-24px"}}
                                >
                                    {textereaErrorMessage}
                                </div>
                                <div className="modal-buttons">
                                    <button className="modal-close" data-dismiss="modal" type="button">بستن</button>
                                    <button className="modal-submit" type="submit" onClick={this.modalSubmitClick}>
                                        <span className="fal fa-check" />
                                        ثبت پیام و فعالسازی وضعیت در دسترس نیستم
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}
 
export default Modal;