import React, {Component} from "react";
import FilterItems from "./FilterItems";

export default class Filters extends Component{
    constructor(props){
        super(props);
        this.state = {
            gender: 0,
            dietCount: 0,
            dietDuration: 0,
            weightDifference: 0,
            dietEnd: 0,
            dietIssue: 0,
            dietStartDate: 0,
            mobile: 0,
            unread:0,
            tag: "",
            calculatedHoobarTime:0
        };
    }

    updateFilter = (filter, value) => {
        switch (filter){
            case ("calculatedHoobarTime"):
                this.setState({
                    calculatedHoobarTime: value
                });
                break;
            case ("dietStartDate"):
                this.setState({
                    dietStartDate: value
                });
                break;
            case ("dietEnd"):
                this.setState({
                    dietEnd: value
                });
                break;
            case("dietIssue"):
                this.setState({
                    dietIssue: value
                });
                break;
            case ("weightDifference"):
                this.setState({
                    weightDifference: value
                });
                break;
            case ("dietDuration"):
                this.setState({
                    dietDuration: value
                });
                break;
            case ("dietCount"):
                this.setState({
                    dietCount: value
                });
                break;
            case ("gender"):
                this.setState({
                    gender: value
                });
                break;
            case ("mobile"):
                this.setState({
                    mobile: value
                });
                break;
            case ("unread"):
                this.setState({
                    unread: value
                });
                break;
            case ("tag"):
            this.setState({
                tag: value
            });
            break;
            default:
                console.log("unhandled filter");
        }
    }
    applyFilters = () => {
        const axios = require('axios');
        let user = sessionStorage.getItem("user");
        axios.get(process.env.REACT_APP_RINGEL_URL + "searchbyfilter/"+user+
        "/?gender="+this.state.gender+
        "&registered_start_date="+this.state.dietStartDate+
        "&passed_x_days_issue_date="+this.state.dietIssue+
        "&passed_x_days_end_date="+this.state.dietEnd+
        "&number_of_diets="+this.state.dietCount+
        "&period="+this.state.dietDuration+
        "&ideal_weight="+this.state.weightDifference+
        "&mobile="+this.state.mobile+
        "&unread="+this.state.unread+
        "&passed_x_days_enter_hoobar="+this.state.calculatedHoobarTime+
        "&tag="+this.state.tag)
            .then((response)=>{
                console.log(response);
                let sortData = response.data;
                if(this.state.dietEnd > 0) {
                    sortData = sortData.sort(function(a,b) {
                        return a.diff < b.diff;
                    });
                } else if (this.state.unread === 1) {
                    sortData = sortData.sort(function(a,b) {
                        return a.lastMessageTime - b.lastMessageTime;
                    });
                }
                this.props.toggleFilters();
                this.props.updateChatList(sortData);
            })
            .catch((error) =>{
                console.log(error);
            });
    }
    render(){
        let options = [];

        Object.keys(this.props.tags).map(
            tag_id => (
                options.push({label: this.props.tags[tag_id], value: tag_id })
            )
        )
        return(
            <React.Fragment>
                <div className="chat-list-header" onClick={this.props.toggleFilters}>
                    <span className="fal fa-arrow-right mr-2" />
                    <span>فیلترها</span>
                </div>
                <div style={{ overflowY: "scroll", maxHeight: "calc(100% - 179px)" }}>
                    {/*<FilterItems filterName={`تاریخ شروع رژیم`} options={"dietStartDate"} updateFilter={this.updateFilter}/>*/}
                    <FilterItems filterName={`موبایل`} options={"mobile"} updateFilter={this.updateFilter} />
                    <FilterItems filterName={`پایان دوره رژیم`} options={"dietEnd"} updateFilter={this.updateFilter} updateIssueDate={this.updateIssueDate} />
                    <FilterItems filterName={`محاسبه زمان حامی اختصاصی`} options={"calculatedHoobarTime"} updateFilter={this.updateFilter}/>
                    <FilterItems filterName={`اختلاف وزن فعلی با وزن ایده‌آل`} options={"weightDifference"} updateFilter={this.updateFilter} />
                    <FilterItems filterName={`طول دوره رژیم`} options={"dietDuration"} updateFilter={this.updateFilter} />
                    <FilterItems filterName={`تعداد دوره رژیم`} options={"dietCount"} updateFilter={this.updateFilter} />
                    <FilterItems filterName={`جنسیت`} options={"gender"} updateFilter={this.updateFilter} />
                    <FilterItems filterName={`برچسب`} options={"tag"} tags={options} updateFilter={this.updateFilter} />
                    <FilterItems filterName={`خوانده نشده`} options={"unread"} updateFilter={this.updateFilter} />
                </div>
                <div className="filter-submit-wrapper">
                            <div className="filter-back-btn" onClick={this.props.toggleFilters}>بازگشت</div>
                            <div className="filter-submit-btn" onClick={this.applyFilters}>اعمال فیلتر</div>
                        </div>
            </React.Fragment>
        );
    }
}