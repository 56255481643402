import React, { Component } from "react";
import ContactItem from "../components/ContactItem";
import Filters from "../components/Filters";
import { Context } from "../App";

class ChatList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilters: false,
      searchInput: "",
      searchResult: [],
      filterResult: [],
      searchActivated: false,
      showTagsList: false,
      filterActivated: false,
      tags: {},
      filteredTags: []
    };
    this.selectedTagId = React.createRef();
    this.filterContacts = [];
  }

  componentWillReceiveProps() {
    this.setState({tags: this.props.tags});
  }

  renderContactItem = (contact) => {
    return (
      <ContactItem
        contact={contact}
        key={contact.telegram_id}
        message={this.props.message}
        ws={this.props.ws}
        setSelectedContact={this.props.setSelectedContact}
        selectedContact={this.props.selectedContact}
        tagOnClick={this.tagOnClick}
      />
    );
  };
  tagOnClick = (e, tag_id, tag_name) => {
    e.stopPropagation();
    this.selectedTagId.current = tag_id;
    this.setState(
      {
        searchInput: `#${tag_name}`,
        searchActivated: true,
      },
      this.search
    );
  };
  searchInputOnChange = (e) => {
    if (e.target.value.substring(0,1) === "#") {
      this.setState({
        showTagsList: true,
        searchInput: e.target.value,
      }, this.filterTags);
    } else {
      this.setState(
        {
          showTagsList: false,
          searchInput: e.target.value,
          searchActivated: e.target.value !== "",
        },
        this.search
      );
    }
  };

  filterTags = () => {
    const {tags, searchInput} = this.state;
    let filteredTags = Object.keys(tags).filter(tag => tags[tag].substring(0,searchInput.length -1) === searchInput.substring(1));
    this.setState({filteredTags});
  }

  search = () => {
    let { contacts } = this.props;
    let {
      searchInput,
      searchActivated,
      filterActivated,
      filterResult,
    } = this.state;
    let searchResult = [];
    let thisContact;
    if (filterActivated) thisContact = filterResult;
    else thisContact = contacts;
    this.setState({
      showTagsList: false,
    });
    if (searchInput.substring(0,1) === "#") {
      if (searchActivated) {
        thisContact.forEach((el) => {
          if (el.tags.includes(this.selectedTagId.current)) {
            searchResult.push(el);
          }
        });
      } else {
        searchResult = [];
      }
    } else {
      if (searchActivated) {
        thisContact.forEach((el) => {
          let match = false;
          if (el.name !== undefined && el.name !== null) {
            if (el.name.toLowerCase().includes(searchInput)) {
              match = true;
            }
          }
          if (el.phoneNumber !== undefined && el.phoneNumber !== null) {
            if (el.phoneNumber.includes(searchInput)) {
              match = true;
            }
          }
          if (match) {
            searchResult.push(el);
          }
        });
      } else {
        searchResult = [];
      }
    }
    this.setState({
      searchResult: searchResult,
    });
  };
  toggleFilters = () => {
    this.setState((prevProps) => ({
      showFilters: !prevProps.showFilters,
    }));
  };
  updateChatList = (chatList) => {
    let filterResult = [];
    let temp;
    if (chatList.length !== 0) {
      for (let i = 0; i < chatList.length; i++) {
        temp = this.props.getContact(chatList[i].message);
        if (temp === undefined) {
          let contact = {
            lastMessage: chatList[i].lastMessage,
            lastMessageTime: chatList[i].lastMessageTime,
            telegram_id: chatList[i].message,
            name: chatList[i].name,
            unreadMessage: chatList[i].unreadMessage,
            phoneNumber:
              chatList[i].phoneNumber !== undefined &&
              chatList[i].phoneNumber !== null
                ? chatList[i].phoneNumber
                : "",
            tags:
              chatList[i].userTags !== undefined &&
              chatList[i].userTags !== null
                ? JSON.parse(chatList[i].userTags)
                : [],
          };

          this.props.addContact(contact);
          temp = this.props.getContact(chatList[i].message);
          this.filterContacts.push(temp);
        }
        filterResult.push(temp);
      }
    }
    this.setState(
      {
        filterActivated: true,
        filterResult: filterResult,
      },
      () => (this.state.searchActivated ? this.search() : null)
    );
  };
  removeFilters = () => {
    for (let i = 0; i < this.filterContacts.length; i++) {
      this.props.removeContact(this.filterContacts[i]);
    }
    this.setState(
      {
        filterActivated: false,
        filterResult: [],
      },
      () => (this.state.searchActivated ? this.search() : null)
    );
    this.selectedTagId.current = null;
  };
  searchIconClick = () => {
    if (this.state.searchActivated)
      this.setState({ searchActivated: false, searchInput: "" });
  };
  render() {
    const {
      showFilters,
      showTagsList,
      searchInput,
      searchActivated,
      searchResult,
      filterActivated,
      filterResult,
      filteredTags,
      tags
    } = this.state;
    return (
      <React.Fragment>
        <div className="row h-100">
          <div className="col-12 no-gutters no-padding h-100">
            {showFilters ? (
              <Filters
                toggleFilters={this.toggleFilters}
                updateChatList={this.updateChatList}
                tags={tags}
              />
            ) : (
              <React.Fragment>
                <div className="chat-list-header">
                  <div className="form-group d-flex position-relative">
                    <input
                      type="text"
                      className="form-control search-input"
                      id="formGroupExampleInput"
                      placeholder="جستجو نام کاربر، شماره موبایل، تگ"
                      onChange={this.searchInputOnChange}
                      style={
                        showTagsList
                          ? {
                              border: "solid 1px #c37dcf",
                              borderBottomRightRadius: "unset",
                              borderBottomLeftRadius: "unset",
                            }
                          : null
                      }
                      value={searchInput}
                    />
                    <span
                      className={
                        searchActivated
                          ? "fal fa-times search-icon"
                          : "fal fa-search search-icon"
                      }
                      onClick={this.searchIconClick}
                    />
                  </div>
                  {showTagsList ? (
                    <ul className="tag-list-drop-down">
                      {filteredTags.map((tag_id) => (
                        <li
                          key={tag_id}
                          id={tag_id}
                          onClick={() => {
                            this.selectedTagId.current = parseInt(tag_id);
                            this.setState(
                              {
                                searchInput: `#${tags[tag_id]}`,
                                searchActivated: true,
                              },
                              this.search
                            );
                          }}
                        >
                          #{tags[tag_id]}
                        </li>
                      ))}
                    </ul>
                  ) : null}
                  <div className="w-100 d-flex">
                    {filterActivated ? (
                      <>
                        <div className="filter-btn">
                          <span>{filterResult.length} نتیجه</span>
                        </div>
                        <div className="filter-btn" onClick={this.removeFilters}>
                          <span className="fal fa-times" />
                          <span>حذف فیلتر</span>
                        </div>
                      </>
                    ) : null}
                    <div className="filter-btn" onClick={this.toggleFilters}>
                      <span className="fal fa-sliders-h" />
                      <span>افزودن فیلتر</span>
                    </div>
                  </div>
                </div>
                <ul
                  className="list-group"
                  style={{
                    direction: "ltr",
                    overflowY: "scroll",
                    position: "absolute",
                    bottom: 0,
                    top: "111px",
                    left: 0,
                    right: 0,
                  }}
                >
                  {this.props.contacts.length !== 0 ? (
                    searchActivated ? (
                      searchResult.map((joinedUser) =>
                        this.renderContactItem(joinedUser)
                      )
                    ) : filterActivated ? (
                      filterResult.map((joinedUser) =>
                        this.renderContactItem(joinedUser)
                      )
                    ) : (
                      this.props.contacts.map((el) => {
                        return this.renderContactItem(el);
                      })
                    )
                  ) : (
                    <div className="text-center ltr">
                      هیچ کاربری برای چت وجود ندارد
                    </div>
                  )}
                </ul>
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default (props) => (
  <Context.Consumer>
    {(state) => (
      <ChatList
        {...props}
        contacts={state.contacts}
        tags={state.tags}
        getContact={state.getContact}
        addContact={state.addContact}
        removeContact={state.removeContact}
      />
    )}
  </Context.Consumer>
);
