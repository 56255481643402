import React, { Component } from "react";
import PersianDate from "persian-date";
import Plyr from "plyr";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default class ChatMessage extends Component {
  constructor(props) {
    super(props);
    let messageText = null;
    if (props.message.text !== undefined && props.message.text != null) {
      messageText = props.message.text.replace(
        "https://mh.beanswide.com",
        "https://hami.ringel.co"
      );
    }
    this.state = {
      chatTime: "",
      chatDate: "",
      showDate: false,
      messageText: messageText,
    };
  }
  componentDidMount() {
    let date = new PersianDate(this.props.message.date);
    this.setState({
      chatTime: date.format("H:mm"),
      chatDate: date.format("D MMMM"),
      showDate: this.props.message.showDate,
    });
    if (
      this.props.message.tag === "user-voice" ||
      this.props.message.tag === "supporter-voice"
    ) {
      if (this.state.messageText !== null && this.state.messageText.indexOf("voice/") !== -1) {
        new Plyr(
          `#${this.state.messageText.split("voice/")[1].split(".")[0]}`,
          {
            settings: [],
            controls: ["play", "progress", "download"],
            volume: 0.9,
            tooltips: { controls: false, seek: false },
          }
        );
      }
    }
  }
  progressbar = () => {
    const { tag } = this.props.message;
    let parentStyle = {},
      background = false,
      backgroundPadding = 0,
      style = {};

    if (tag === "supporter-voice") {
      parentStyle.left = "14px";
      background = true;
      backgroundPadding = 6;
      style = {
        backgroundColor: "#9c27b0",
        pathColor: "#fff",
        trailColor: "transparent",
      };
    } else if (tag === "supporter-photo") {
      parentStyle.left = "50%";
      parentStyle.top = "50%";
      parentStyle.transform = "translate(-50%, -50%)";
      style = {
        pathColor: "#9c27b0",
        trailColor: "#fff",
      };
    }

    return (
      <div
        style={{
          ...parentStyle,
          position: "absolute",
          width: "48px",
          zIndex: 1,
        }}
      >
        <CircularProgressbarWithChildren
          value={this.props.uploadProgress}
          background={background}
          backgroundPadding={backgroundPadding}
          styles={buildStyles(style)}
        >
          <div
            className="fa fal fa-times cancel-upload"
            onClick={() => this.props.message.cancelUpload()}
          ></div>
        </CircularProgressbarWithChildren>
      </div>
    );
  };
  render() {
    const { tag } = this.props.message;
    return (
      <React.Fragment>
        {this.state.showDate ? (
          <div className="chat-date">{this.state.chatDate}</div>
        ) : null}
        <div
          className={`chat-message ${
            tag === "user" ||
            tag === "user-voice" ||
            tag === "link" ||
            tag === "user-photo" ||
            tag === "user-video" ||
            tag === "user-file"
              ? "chat-message-other"
              : "chat-message-self"
          }`}
          style={{
            padding:
              tag === "user-photo" ||
              tag === "user-video" ||
              tag === "supporter-photo"
                ? "0"
                : "",
          }}
        >
          {this.props.uploadProgress !== null && this.progressbar()}
          {this.state.messageText != null && ((tag === "user-voice" || tag === "supporter-voice") &&
          this.state.messageText.indexOf("voice/") !== -1 ? (
            <audio
              id={`${this.state.messageText.split("voice/")[1].split(".")[0]}`}
              className="voice-link"
              controls={true}
              src={`${this.state.messageText}`}
              preload="none"
            ></audio>
          ) : tag === "user-file" || tag === "supporter-file" ? (
            <a
              href={this.state.messageText}
              target="_blank"
              className="file"
              rel="noopener noreferrer"
            >
              <div className="file-icon">
                <span className="fal fa-file" />
              </div>
              <span>
                {this.state.messageText.split(".").pop().toUpperCase()}
              </span>
            </a>
          ) : tag === "link" || tag === "supporter-link" ? (
            <a
              href={
                this.state.messageText && this.state.messageText.split(" ")[0]
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {this.state.messageText}
            </a>
          ) : tag === "user-photo" || tag === "supporter-photo" ? (
            <>
              <a href={`#${this.state.messageText}`}>
                <img
                  className="photo-message"
                  src={this.state.messageText}
                  alt={this.state.messageText}
                />
              </a>
              <div
                className="photo-message-target-container"
                id={this.state.messageText}
                onClick={() => {
                  console.log(window.location);
                  window.location.href = window.location.href.replace(
                    window.location.hash,
                    "#"
                  );
                }}
              >
                <img
                  className="photo-message-target"
                  src={this.state.messageText}
                  alt={this.state.messageText}
                />
              </div>
            </>
          ) : tag === "user-video" || tag === "supporter-video" ? (
            <video
              className="video-message"
              src={this.state.messageText}
              controls={true}
              preload="metadata"
            ></video>
          ) : (
            <div style={{ padding: "0 0 8px" }}>{this.state.messageText}</div>
          ))}
          <div className="chat-time">{this.state.chatTime}</div>
          {this.props.loading && <div className="message-loading"></div>}

          {this.props.status && (
            <div className="chat-status">
              {this.props.status === "sending" && <>در حال ارسال</>}
              {this.props.status === "sent" && (
                <>
                  ارسال شد&nbsp;<i className="fal fa-check">&nbsp;</i>
                </>
              )}
              {this.props.status === "sentBefore" && (
                <i className="fal fa-check">&nbsp;</i>
              )}
              {this.props.status === "failed" && (
                <>
                  ارسال نشد&nbsp;<i className="fal fa-times">&nbsp;</i>
                </>
              )}
            </div>
          )}
        </div>
        {/*<a className="voice-link" href={`${this.state.messageText}`} target="_blank">{this.state.messageText}</a> :*/}
      </React.Fragment>
    );
  }
}
