import React, { Component } from "react";
import UserImage from "../style/img/user_image.png";
import { last_chat_time, to_persian_digits } from "../utility";
import { Context } from "../App";

class ContactItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: last_chat_time(this.props.contact.lastMessageTime),
      active: false,
    };
  }
  openChat = () => {
    this.props.setSelectedContact(this.props.contact.telegram_id);
  };
  render() {
    const { contact } = this.props;
    return (
      <React.Fragment>
        {
          <li
            title={contact.phoneNumber}
            className={`list-group-item list-group-item-action d-flex flex-column ${
              this.props.selectedContact === contact.telegram_id ? "active" : ""
            }`}
            onClick={this.openChat}
          >
            <div className="join-card-info-wrapper d-flex justify-content-between align-items-center">
              <div className="user-image">
                <img src={UserImage} alt="User" />
                {contact.limoomeMember ? (
                  <div className="limoome-member" />
                ) : null}
              </div>
              <div className="rtl user-name-wrapper">
                {contact.name !== null ||
                contact.name !== undefined ||
                contact.name !== "" ? (
                  <div className="">{contact.name}</div>
                ) : (
                  <div className="">{contact.message}</div>
                )}
                {contact.lastMessage !== undefined &&
                contact.lastMessage !== null ? (
                  <div className="rtl last-message">
                    {contact.lastMessage.length > 30
                      ? contact.lastMessage.substring(0, 30) + "..."
                      : contact.lastMessage}
                  </div>
                ) : null}
              </div>
              <div className="message-date-wrapper">
                <div className="message-date">{this.state.date}</div>
                {contact.unreadMessage !== undefined &&
                contact.unreadMessage !== 0 ? (
                  <div
                    className="message-badge"
                    style={{
                      backgroundColor: `${
                        this.state.active ? "#fff" : "#9c27b0"
                      }`,
                      color: `${this.state.active ? "#9c27b0" : "#fff"}`,
                    }}
                  >
                    {contact.unreadMessage < 0 ? (
                      <div
                        className="badge-join"
                        style={{
                          borderColor: `${
                            this.state.active ? "#9c27b0" : "#fff"
                          }`,
                        }}
                      />
                    ) : (
                      <span>
                        {to_persian_digits(contact.unreadMessage.toString())}
                      </span>
                    )}
                  </div>
                ) : (
                  contact.lastMessage === "کاربر دوباره رژیم خریده" && (
                    <div
                      className="message-badge"
                      style={{
                        backgroundColor: `${
                          this.state.active ? "#fff" : "#9c27b0"
                        }`,
                        color: `${this.state.active ? "#9c27b0" : "#fff"}`,
                      }}
                    >
                      <div
                        className="badge-join"
                        style={{
                          borderColor: `${
                            this.state.active ? "#9c27b0" : "#fff"
                          }`,
                        }}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
            {this.props.contact.tags.length !== 0 ? (
              <div className="join-card-tag-wrapper">
                {this.props.contact.tags.map((tag_id) => (
                  tag_id && 
                  <div
                    className="tag"
                    style={{"backgroundColor" : this.props.getTagCustomColor(tag_id)  }}
                    key={tag_id}
                    title={this.props.idToTag(tag_id)}
                    onClick={(e) =>
                      this.props.tagOnClick(
                        e,
                        tag_id,
                        this.props.idToTag(tag_id)
                      )
                    }
                  >
                    {this.props.idToTag(tag_id)}
                  </div>
                ))}
              </div>
            ) : null}
          </li>
        }
      </React.Fragment>
    );
  }
}
export default (props) => (
  <Context.Consumer>
    {(state) => <ContactItem {...props} idToTag={state.idToTag} getTagCustomColor={state.getTagCustomColor} />}
  </Context.Consumer>
);
