import React, { Component } from "react";
import HealthData from "../components/HealthData";
import ShowDetail from "../components/ShowDetail";
import Tag from "../components/Tag";
import { Context } from "../App";
import axios from "axios";

class ChatData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: null,
      fileNumber: 0,
      healthData: null,
      personalData: null,
      dietData: null,
      additionalData: null,
      physicalData: null,
      importantHealthData: null,
      showDetailHealthData: false,
      descriptionShow: false,
      description: "",
      descriptionLoader: false,
      dietNumber: null,
      expieredHoobarAt: null,
    };
  }
  componentDidMount() {
    this.getUserData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.telegram_id !== prevProps.telegram_id) {
      this.setState(
        {
          userData: null,
          fileNumber: 0,
          healthData: null,
          description: "",
          descriptionShow: false,
        },
        this.getUserData
      );
    }
  }
  getUserData = () => {
    const axios = require("axios");
    axios
      .get(
        process.env.REACT_APP_RINGEL_URL +
          "userindentity/telegramid/" +
          this.props.telegram_id
      )
      .then((response) => {
        if (response.data !== "") {
          let description = response.data.description;
          if (description === null) {
            description = "";
          }
          this.setState(
            {
              userData: response.data,
              fileNumber: response.data.fileNumber,
              description: description,
              descriptionShow: true,
            },
            this.getHealthData
          );
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          description: "",
          descriptionShow: false,
        });
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let description = this.state.description;
    let config = {
      headers: {
        username: sessionStorage.getItem("user"),
        password: sessionStorage.getItem("tk"),
        "Content-Type": "text/plain",
      },
    };
    this.setState({ descriptionLoader: true });
    axios
      .put(
        process.env.REACT_APP_RINGEL_URL +
          "userindentity/" +
          this.props.telegram_id +
          "/",
        description,
        config
      )
      .then((res) => {
        this.setState({ descriptionLoader: false });
        if (res.data !== "ok") {
          alert("توضیحات ثبت نشد! لطفا مجددا دکمه ذخیره را بزنید.");
        }
      })
      .catch((error) => {
        this.setState({ descriptionLoader: false });
        alert("توضیحات ثبت نشد! لطفا مجددا دکمه ذخیره را بزنید.");
      });
  };
  handleChange = (event) => {
    this.setState({ description: event.target.value });
  };

  getHealthData = () => {
    if (this.state.userData !== null) {
      let personalData = [],
        dietData = [],
        additionalData = [],
        physicalData = [],
        importantHealthData = [];
      personalData.push({
        farsi_name: "نام و نام خانوادگی",
        value: this.state.userData.name,
      });
      const axios = require("axios");
      let fileNumber =
        this.state.userData.fileNumber !== null
          ? this.state.userData.fileNumber
          : "X";
      axios
        .get(
          process.env.REACT_APP_LIMOOME_URL +
            "dsjdhsjhdsjd/sdfghjklkjhgf6789cvbnm12233mnbvcvbn?telegramid=" +
            this.props.telegram_id +
            "&phonenumber=" +
            encodeURIComponent(this.state.userData.phoneNumber) +
            "&filenumber=" +
            fileNumber
        )
        .then((response) => {
          let data = response.data;
          if (data !== "NOT_FOUND") {
            let valueList = Object.values(data);
            // console.log("valueList: " + valueList);
            this.extractPersonalDataFromValueList(personalData, valueList);
            this.extractDietDataFromValueList(dietData, valueList);
            this.extractAdditionalDataFromValueList(additionalData, valueList);
            this.extractPhysicalDataFromValueList(physicalData, valueList);
            this.extractImportantHealthDataFromValueList(
              importantHealthData,
              valueList
            );
            valueList = valueList.filter(
              (item) =>
                item.farsi_name !== " شماره تلفن" &&
                item.farsi_name !== " شماره پرونده" &&
                item.farsi_name !== "سن کاربر " &&
                item.farsi_name !== "جنسیت" &&
                item.farsi_name !== "وزن ایده آل" &&
                item.farsi_name !== " تاریخ ثبت رژیم" &&
                item.farsi_name !== " تاریخ صدور رژیم" &&
                item.farsi_name !== " تاریخ شروع رژیم" &&
                item.farsi_name !== " صادر کننده رژیم" &&
                item.farsi_name !== " عنوان رژیم" &&
                item.farsi_name !== " علت اصلی درخواست رژیم غذایی" &&
                item.farsi_name !== "صبحانه" &&
                item.farsi_name !== "ناهار" &&
                item.farsi_name !== "شام" &&
                item.farsi_name !== "قد" &&
                item.farsi_name !== "وزن" &&
                item.farsi_name !== " سردرد" &&
                item.farsi_name !== " کرونا" &&
                item.farsi_name !== " گیاهخوار بودن" &&
                item.farsi_name !== " رژیم اقتصادی" &&
                item.farsi_name !== " گرفتگی عضلات" &&
                item.farsi_name !== " خوابرفتگی دست و پا" &&
                item.farsi_name !== "پرش از خواب" &&
                item.farsi_name !== " شماره رژیم" &&
                item.farsi_name !== " اطلاعات بسته" &&
                item.farsi_name !== " اطلاعات بسته حامی" &&
                item.farsi_name !== " تاریخ انقضا حامی" &&
                item.farsi_name !== "تعداد رژیم"
            );
            valueList = importantHealthData.concat(valueList);
            this.setState({
              healthData: valueList,
              personalData: personalData,
              dietData: dietData,
              additionalData: additionalData,
              physicalData: physicalData,
              importantHealthData: importantHealthData,
            });
          } else {
            this.setState({
              healthData: null,
              personalData: null,
              dietData: null,
              additionalData: null,
              physicalData: null,
              importantHealthData: null,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  extractPersonalDataFromValueList = (personalData, valueList) => {
    let phoneNumber = valueList.find(
      (item) => item.farsi_name === " شماره تلفن"
    );
    if (phoneNumber && phoneNumber.value) {
      personalData.push({
        farsi_name: "شماره موبایل",
        value: phoneNumber.value,
      });
    }

    let fileNumber = valueList.find(
      (item) => item.farsi_name === " شماره پرونده"
    );
    if (fileNumber && fileNumber.value) {
      personalData.push({
        farsi_name: "شماره پرونده",
        value: fileNumber.value,
      });
    }

    let genderTitle = valueList.find((item) => item.farsi_name === "جنسیت");
    if (genderTitle && genderTitle.value) {
      personalData.push({
        farsi_name: "جنسیت",
        value: genderTitle.value,
      });
    }

    let dietCount = valueList.find((item) => item.farsi_name === " تعداد رژیم");
    if (dietCount && dietCount.value) {
      personalData.push({ farsi_name: " تعداد رژیم", value: dietCount.value });
    }

    let userAge = valueList.find((item) => item.farsi_name === "سن کاربر ");
    if (userAge && userAge.value) {
      let ageIndex = userAge.value.indexOf("سن");
      let birthDate = userAge.value
        .substring(0, ageIndex - 1)
        .replace(/-/g, " / ");
      let age = userAge.value.substring(ageIndex + 2, ageIndex + 4);
      personalData.push(
        { farsi_name: "سن", value: age },
        { farsi_name: "تاریخ تولد", value: birthDate }
      );
    }
  };
  extractDietDataFromValueList = (dietData, valueList) => {
    let registerDate = valueList.find(
      (item) => item.farsi_name === " تاریخ ثبت رژیم"
    );
    if (registerDate && registerDate.value) {
      dietData.push({
        farsi_name: "تاریخ ثبت",
        value: registerDate.value.replace(/-/g, " / "),
      });
    }
    let issueDate = valueList.find(
      (item) => item.farsi_name === " تاریخ صدور رژیم"
    );
    if (issueDate && issueDate.value) {
      dietData.push({
        farsi_name: "تاریخ صدور",
        value: issueDate.value.replace(/-/g, " / "),
      });
    }

    let startDate = valueList.find(
      (item) => item.farsi_name === " تاریخ شروع رژیم"
    );
    if (startDate && startDate.value) {
      dietData.push({
        farsi_name: "تاریخ شروع",
        value: startDate.value.replace(/-/g, " / "),
      });
    } else if (startDate && !startDate.value) {
      dietData.push({ farsi_name: "تاریخ شروع", value: "--" });
    }

    let organizer = valueList.find(
      (item) => item.farsi_name === " صادر کننده رژیم"
    );
    if (organizer && organizer.value) {
      dietData.push({ farsi_name: "تنظیم کننده", value: organizer.value });
    }

    let dietTitle = valueList.find((item) => item.farsi_name === " عنوان رژیم");
    if (dietTitle && dietTitle.value) {
      dietData.push({ farsi_name: "عنوان رژیم", value: dietTitle.value });
    }

    let dietNumber = valueList.find(
      (item) => item.farsi_name === " شماره رژیم"
    );
    if (dietNumber && dietNumber.value) {
      dietData.push({ farsi_name: "شماره رژیم", value: dietNumber.value });
    }
    let packageInformation = valueList.find(
      (item) => item.farsi_name === " اطلاعات بسته"
    );
    if (packageInformation && packageInformation.value) {
      dietData.push({
        farsi_name: " اطلاعات بسته",
        value: packageInformation.value,
      });
    }

    let hamiPackageInformation = valueList.find(
      (item) => item.farsi_name === " اطلاعات بسته حامی"
    );
    if (hamiPackageInformation && hamiPackageInformation.value) {
      dietData.push({
        farsi_name: " اطلاعات بسته حامی",
        value: hamiPackageInformation.value,
      });
    }

    let expiredSpecialHami = valueList.find(
      (item) => item.farsi_name === " تاریخ انقضا حامی"
    );
    if (expiredSpecialHami && expiredSpecialHami.value) {
      dietData.push({
        farsi_name: " تاریخ انقضا حامی",
        value: expiredSpecialHami.value.replace(/-/g, " / "),
      });
    } else if (expiredSpecialHami && !expiredSpecialHami.value) {
      dietData.push({ farsi_name: " تاریخ انقضا حامی", value: "--" });
    }

    this.setState({ dietNumber: dietNumber.value });
  };
  extractAdditionalDataFromValueList = (additionalData, valueList) => {
    let reasonDiet = valueList.find(
      (item) => item.farsi_name === " علت اصلی درخواست رژیم غذایی"
    );
    if (reasonDiet && reasonDiet.value) {
      additionalData.push({ farsi_name: "علت رژیم", value: reasonDiet.value });
    }

    let vegetarian = valueList.find(
      (item) => item.farsi_name === " گیاهخوار بودن"
    );
    if (vegetarian && vegetarian.value) {
      additionalData.push({ farsi_name: "گیاهخواری", value: vegetarian.value });
    }

    let economic = valueList.find(
      (item) => item.farsi_name === " رژیم اقتصادی"
    );
    if (economic && economic.value) {
      additionalData.push({
        farsi_name: "رژیم اقتصادی",
        value: economic.value,
      });
    }

    let breakfast = valueList.find((item) => item.farsi_name === "صبحانه");
    if (breakfast && breakfast.value) {
      //   additionalData.push({farsi_name: "صبحانه", value: breakfast.value});
    }

    let lunch = valueList.find((item) => item.farsi_name === "ناهار");
    if (lunch && lunch.value) {
      //   additionalData.push({farsi_name: "ناهار", value: lunch.value});
    }

    let dinner = valueList.find((item) => item.farsi_name === "شام");
    if (dinner && dinner.value) {
      //   additionalData.push({farsi_name: "شام", value: dinner.value});
    }
  };
  extractPhysicalDataFromValueList = (physicalData, valueList) => {
    let height = valueList.find((item) => item.farsi_name === "قد");
    if (height && height.value) {
      physicalData.push({ farsi_name: "قد", value: height.value });
    }

    let weight = valueList.find((item) => item.farsi_name === "وزن");
    if (weight && weight.value) {
      physicalData.push({ farsi_name: "وزن", value: weight.value });
    }

    let idealWeight = valueList.find(
      (item) => item.farsi_name === "وزن ایده آل"
    );
    if (idealWeight && idealWeight.value) {
      physicalData.push({
        farsi_name: "وزن ایده آل",
        value: idealWeight.value,
      });
    }
  };
  extractImportantHealthDataFromValueList = (
    importantHealthData,
    valueList
  ) => {
    let covid19 = valueList.find((item) => item.farsi_name === " کرونا");
    if (covid19 && covid19.value) {
      importantHealthData.push({ farsi_name: "کرونا", value: covid19.value });
    }

    let headache = valueList.find((item) => item.farsi_name === " سردرد");
    if (headache && headache.value) {
      importantHealthData.push({ farsi_name: "سردرد", value: headache.value });
    }

    let cramp = valueList.find((item) => item.farsi_name === " گرفتگی عضلات");
    if (cramp && cramp.value) {
      importantHealthData.push({
        farsi_name: "گرفتگی عضلات",
        value: cramp.value,
      });
    }

    let limbs = valueList.find(
      (item) => item.farsi_name === " خوابرفتگی دست و پا"
    );
    if (limbs && limbs.value) {
      importantHealthData.push({
        farsi_name: "خواب رفتگی دست و پا",
        value: limbs.value,
      });
    }

    let sleepJump = valueList.find((item) => item.farsi_name === "پرش از خواب");
    if (sleepJump && sleepJump.value) {
      importantHealthData.push({
        farsi_name: "پرش از خواب",
        value: sleepJump.value,
      });
    }
  };
  minimumDate = () => {
    let temp = null;
    this.state.dietData.forEach((element) => {
      if (element.farsi_name === "تاریخ صدور") {
        temp = {
          year: parseInt(element.value.split("/")[2]),
          month: parseInt(element.value.split("/")[1]),
          day: parseInt(element.value.split("/")[0]),
        };
      }
    });
    return temp;
  };
  showHealthDetail = () => {
    this.setState({ showDetailHealthData: !this.state.showDetailHealthData });
  };

  render() {
    let fileNumber =
      this.state.fileNumber !== null ? this.state.fileNumber : "X";
    const {
      personalData,
      healthData,
      dietData,
      additionalData,
      physicalData,
      showDetailHealthData,
      importantHealthData,
    } = this.state;
    return (
      <React.Fragment>
        <div
          className="row row-height h-100 justify-content-start position-relative chat-data"
          style={{ overflowY: "scroll", direction: "ltr" }}
        >
          {!this.props.lg && (
            <div
              className="position-absolute cursor-pointer fal fa-times"
              style={{
                top: "18px",
                left: "18px",
                zIndex: 5,
              }}
              onClick={this.props.toggleShowChatData}
            ></div>
          )}

          {this.props.lg && (
            <div className="col-12 no-padding no-gutters">
              {this.props.getContact(this.props.telegram_id).name}
              ({this.props.getContact(this.props.telegram_id).phoneNumber})
              <hr/>
            </div>
          )}

          <Tag marginTop={0} telegram_id={this.props.telegram_id} />

          {this.state.descriptionShow && (
            <div className="col-12 no-padding no-gutters mt-2">
              <form onSubmit={this.handleSubmit}>
                <textarea
                  className="form-control rtl"
                  name="description"
                  placeholder="توضیحات خود را بنویسید"
                  value={this.state.description}
                  onChange={this.handleChange}
                  rows="3"
                ></textarea>

                <button type="submit" className="chat-data-diet-btn save-btn">
                  ذخیره
                  {this.state.descriptionLoader && (
                    <div class="loader-sm"></div>
                  )}
                </button>
              </form>
            </div>
          )}

          {healthData !== null ? (
            <div className="col-12 no-padding">
              <div className="row no-gutters no-padding">
                <div className="col-12 h-100 justify-content-start align-items-center position-relative">
                  <div className="chat-data-subject">مشخصات فردی</div>
                  <a
                    href={
                      process.env.REACT_APP_LIMOOME_URL +
                      `viewdiet/sdfghjklkjhgf6789cvbnm12233mnbvcvbn/${this.state.dietNumber}?filenumber=${fileNumber}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="chat-data-diet-btn"
                    style={{ color: "unset" }}
                  >
                    <div
                      className="w-100 h-100 d-flex flex-column justify-content-center"
                      style={{ padding: "0 5px" }}
                    >
                      مشاهده رژیم غذایی
                    </div>
                  </a>
                  <a
                    href={
                      process.env.REACT_APP_LIMOOME_URL +
                      `getpdf/sdfghjklkjhgf6789cvbnm12233mnbvcvbn/${this.state.dietNumber}.pdf?filenumber=${fileNumber}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="chat-data-diet-btn"
                    style={{ color: "unset" }}
                  >
                    <div
                      className="w-100 h-100 d-flex flex-column justify-content-center"
                      style={{ padding: "0 5px" }}
                    >
                      دانلود رژیم غذایی
                    </div>
                  </a>

                  <div className="row no-gutters no-padding">
                    <div className="col-12 no-gutters">
                      <div className="chat-data-box">
                        {personalData !== null
                          ? personalData.map((personalData, index) => (
                              <HealthData
                                healthData={personalData}
                                fileNumber={fileNumber}
                                key={"personal" + index}
                              />
                            ))
                          : null}
                      </div>
                      <div className="chat-data-subject">مشخصات رژیم</div>
                      <div className="chat-data-box">
                        {dietData !== null
                          ? dietData.map((dietData, index) => (
                              <HealthData
                                healthData={dietData}
                                fileNumber={fileNumber}
                                key={"diet" + index}
                                minimumDate={
                                  dietData.farsi_name === "تاریخ شروع"
                                    ? this.minimumDate()
                                    : null
                                }
                              />
                            ))
                          : null}
                      </div>
                      <div className="chat-data-subject">مشخصات تکمیلی</div>
                      <div className="chat-data-box">
                        {additionalData !== null
                          ? additionalData.map((additionalData, index) => (
                              <HealthData
                                healthData={additionalData}
                                fileNumber={fileNumber}
                                key={"additional" + index}
                              />
                            ))
                          : null}
                      </div>
                      <div className="chat-data-subject">مشخصات فیزیک</div>
                      <div className="chat-data-box">
                        {physicalData !== null
                          ? physicalData.map((physicalData, index) => (
                              <HealthData
                                healthData={physicalData}
                                fileNumber={fileNumber}
                                key={"physical" + index}
                              />
                            ))
                          : null}
                      </div>
                      <div className="chat-data-subject">مشخصات پزشکی</div>
                      <div
                        className="chat-data-box"
                        style={{ marginBottom: "24px" }}
                      >
                        {showDetailHealthData
                          ? healthData !== null
                            ? healthData.map((healthData, index) => (
                                <HealthData
                                  healthData={healthData}
                                  fileNumber={fileNumber}
                                  key={"health" + index}
                                />
                              ))
                            : null
                          : importantHealthData !== null
                          ? importantHealthData.map(
                              (importantHealthData, index) => (
                                <HealthData
                                  healthData={importantHealthData}
                                  fileNumber={fileNumber}
                                  key={"important" + index}
                                />
                              )
                            )
                          : null}
                        <ShowDetail
                          onClick={this.showHealthDetail}
                          showDetail={showDetailHealthData}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}
export default (props) => (
  <Context.Consumer>
    {(state) => (
      <ChatData {...props} lg={state.lg} getContact={state.getContact} />
    )}
  </Context.Consumer>
);
