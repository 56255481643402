import React, {Component} from "react";
import SupporterImage from "../style/img/supporter_image.png";
// import DailyReport from "../pages/DailyReport";
import DailyReportPopup from "./DailyReportPopup";

class SupporterReportCard extends Component{
    constructor(props){
        super(props);
        this.state = {
            showSupporterReportPopup: false
        };
        this.showSupporterReportPopup = this.showSupporterReportPopup.bind(this);
    }
    showSupporterReportPopup(){
        this.setState(prevState=>({
            showSupporterReportPopup: !prevState.showSupporterReportPopup
        }));
    }
    render(){
        return(
            <React.Fragment>
                <div className={`col-4 p-2 ${this.props.className}`}>
                    <div className="supporter-report-card">
                        <div className="row no-gutters" style={{height: "74px"}}>
                            <div className="col-2 d-flex justify-content-center align-items-center">
                                <div className="user-image" style={{marginLeft: 0}}>
                                    <img src={SupporterImage} alt="User"/>
                                </div>
                            </div>
                            <div className="col-8 d-flex flex-column justify-content-center align-items-start">
                                <div className="supporter-name">{this.props.supporter.name}</div>
                                <div className="supporter-role">{this.props.supporter.supporterType}</div>
                            </div>
                            <div className="col-2 d-flex flex-column justify-content-center align-items-end">
                                <div className={(this.props.supporter.userOnlineStatus === "IDLE") ? "supporter-report-status-active" : "supporter-report-status-deactive"}>
                                    {
                                        (this.props.supporter.userOnlineStatus === "IDLE") ? "فعال" : "غیرفعال"
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters" style={{height: "48px", backgroundColor: "#fafafa"}}>
                            <div className="col-6"></div>
                            <div className="col-6 report-card-btn" onClick={this.showSupporterReportPopup}>
                                <span className="fal fa-chart-bar" />
                                گزارش عملکرد
                            </div>
                        </div>
                    </div>
                </div>
                {
                    (this.state.showSupporterReportPopup) ?
                        <DailyReportPopup supporter={this.props.supporter} closePopup={this.showSupporterReportPopup}/> :
                        null
                }
            </React.Fragment>
        );
    }
}
export default SupporterReportCard;