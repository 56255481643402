import React, { Component } from "react";
import MainChatPanel from "./MainChatPanel";
import { Context } from "../App";
const axios = require("axios");

const ServerAlert = ({ serverAlertMessage }) => {
  alert(serverAlertMessage);
  window.location.reload();
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.ws = null;
    this.pingInterval = null;
    this.pongTime = 0;
    this.state = {
      socketPingInterval: 5,
      origin: null,
      password: null,
      loginErrorMessage: "",
      loggedIn: false,
      message: null,
      disableLoginBtn: false,
      adminData: null,
      connected: false,
      serverAlert: false,
      serverAlertMessage: "",
      isOffline: false,
    };
  }
  updateOrigin = (event) => {
    this.setState({
      origin: event.target.value,
    });
    sessionStorage.setItem("from", event.target.value);
  };
  updatePass = (event) => {
    this.setState({
      password: event.target.value,
    });
  };
  checkData = (e) => {
    e.preventDefault();
    if (this.state.origin === "" || this.state.origin === null) {
      this.setState({
        loginErrorMessage: "نام کاربری نمی‌تواند خالی باشد",
      });
    } else {
      if (this.state.password === "" || this.state.password === null) {
        this.setState({
          loginErrorMessage: "رمز عبور نمی‌تواند خالی باشد",
        });
      } else {
        this.signIn();
      }
    }
  };
  signIn = () => {
    this.setState({
      disableLoginBtn: true,
    });
    let origin = this.state.origin;
    let password = this.state.password;
    axios
      .get(
        process.env.REACT_APP_RINGEL_URL +
          "supp/" +
          origin +
          "/" +
          password +
          "/"
      )
      .then((response) => {
        console.log(response);
        if (response.data !== "") {
          this.setState({
            adminData: response.data,
          });
          if (response.data.brbMessage !== null) {
            this.setState({ isOffline: true });
            sessionStorage.setItem("message", response.data.brbMessage);
          } else this.setState({ isOffline: false });
          this.socketConnect();
        } else {
          this.setState({
            loginErrorMessage: "نام کاربری یا رمز عبور اشتباه است",
            disableLoginBtn: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loginErrorMessage: "خطا در اتصال",
          disableLoginBtn: false,
        });
      });
  };
  socketConnect = () => {
    let origin = this.state.origin;
    let password = this.state.password;
    let queryString = origin + "=" + password;

    this.ws = new WebSocket(
      process.env.REACT_APP_RINGEL_WS + "name?" + queryString
    );
    this.ws.onopen = () => {
      console.log("Web Socket Connected Successfully");
      if (this.ws.readyState === 1) {
        sessionStorage.setItem("user", this.state.origin);
        sessionStorage.setItem("tk", this.state.password);
        this.setState({
          loggedIn: true,
          connected: true,
        });
        this.pingPong();
      }
    };
    this.ws.onerror = (error) => {
      console.log(error);
      this.setState({
        disableLoginBtn: false,
      });
    };
    this.ws.onclose = () => {
      this.setState({
        connected: false,
      });
      this.socketConnect();
    };
    this.ws.onmessage = (message) => {
      if (message.data === "p") {
        this.pongTime = Date.now();
      } else {
        let response = JSON.parse(message.data);
        switch (response.operationNumber) {
          case "14":
            if (response.from === this.state.origin) {
              response.from = response.to;
            } else {
            }
            this.handleMessage(response, "user-voice");
            break;
          case "12":
            this.handleUserJoin(response);
            break;
          case "23":
            this.handleUserRemoved(response);
            break;
          case "5":
            if (response.from === this.state.origin) {
              response.from = response.to;
              this.handleMessage(
                response,
                response.message.includes("http") ? "supporter-link" : "self"
              );
            } else {
              this.handleMessage(
                response,
                response.message.includes("http") ? "link" : "user"
              );
            }
            break;
          case "19":
            if (response.from === this.state.origin) {
              response.from = response.to;
            } else {
            }
            this.handleMessage(response, "user-photo");
            break;
          case "20":
            if (response.from === this.state.origin) {
              response.from = response.to;
            } else {
            }
            this.handleMessage(response, "user-video");
            break;
          case "21":
            if (response.from === this.state.origin) {
              response.from = response.to;
            } else {
            }
            this.handleMessage(response, "user-file");
            break;
          case "22":
            this.setState({
              serverAlert: true,
              serverAlertMessage: response.message,
            });
            break;
          case "26":
            this.handleMessageStatus(response);
            break;
          case "27":
            this.handleMessageStatus(response);
            break;
          default:
            console.log("unhandled operation");
        }
      }
    };
  };
  handleMessage = (response, messageType) => {
    // this.playAudio();
    this.setState({
      message: {
        from: response.from,
        text: response.message,
        tag: messageType,
      },
    });
    this.updateContactInfo(response);
  };

  handleMessageStatus = (response) => {
    this.props.setMessageAcknowledge({
      user: response.to,
      timestamp: response.timestamp,
      operationNumber: response.operationNumber,
      text: response.message,
    });
  };

  handleUserJoin = (response) => {
    let user = {
      lastMessage: response.lastMessage,
      lastMessageTime: response.lastMessageTime,
      telegram_id: response.message,
      name: response.name,
      unreadMessage: response.unreadMessage,
      phoneNumber:
        response.phoneNumber !== undefined ? response.phoneNumber : "",
      tags:
        response.userTags !== undefined ? JSON.parse(response.userTags) : [],
      from: response.from,
    };
    this.props.addContact(user);
  };

  handleUserRemoved = (response) => {
    this.props.removeContact({ telegram_id: response.message });
  };

  playAudio = () => {
    let audio = document.getElementById("audio");
    if (audio !== null) {
      audio.currentTime = 0;
      audio.play();
    }
  };
  updateContactInfo = (response) => {
    let contacts = [...this.props.contacts];
    let index = contacts.findIndex((user) => {
      return user.telegram_id === response.from;
    });
    if (index !== -1) {
      contacts[index].lastMessageTime = response.lastMessageTime;
      contacts[index].unreadMessage = response.unreadMessage;
      contacts[index].lastMessage = response.message;
      let temp = contacts[index];
      contacts.splice(index, 1);
      contacts.unshift(temp);
    }
    this.props.updateContact(contacts);
  };

  handleOffline = (message) => {
    let { origin, password, isOffline } = this.state;
    if (!isOffline || message !== undefined) {
      axios({
        method: "post",
        url:
          process.env.REACT_APP_RINGEL_URL +
          "supp/message/" +
          origin +
          "/" +
          password +
          "/",
        headers: { "content-type": "text/plain" },
        data: sessionStorage.getItem("message"),
      })
        .then((response) => {
          console.log(response);
          this.setState({ isOffline: true });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios({
        method: "post",
        url:
          process.env.REACT_APP_RINGEL_URL +
          "supp/unsetmessage/" +
          origin +
          "/" +
          password +
          "/",
      })
        .then((response) => {
          console.log(response);
          this.setState({ isOffline: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  pingPongInterval = () => {
    // console.log('Piiiiiiiiiiiiiiiiiing Interval');
    if (this.pingInterval !== null) {
      window.clearTimeout(this.pingInterval);
    }
    this.pingInterval = setTimeout(() => {
      if (Date.now() - this.pongTime > this.state.socketPingInterval * 1000) {
        console.log("Nooooo Ping");
        this.ws.close();
        this.setState({
          connected: false,
        });
        this.setState({
          // loggedIn: false,
          disableLoginBtn: false,
          // joinedUserList: []
        });
      } else {
        this.pingPong();
      }
    }, this.state.socketPingInterval * 1000);
  };
  pingPong = () => {
    // console.log('%c Ping', 'color: lime');
    if (this.ws.readyState === 1) {
      this.ws.send("p");
      this.pingPongInterval();
    }
  };
  render() {
    return (
      <React.Fragment>
        {!this.state.loggedIn ? (
          <div className="container-fluid h-100 bg-main">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-lg-5 col-md-5 col-sm-6 col-12">
                <div className="card login-card text-center">
                  <div className="card-body no-padding">
                    <div className="login-image"></div>
                    <form className="form-signin" onSubmit={this.checkData}>
                      <label htmlFor="inputEmail" className="login-input-label">
                        نام کاربری
                      </label>
                      <input
                        type="text"
                        id="inputEmail"
                        className="form-control login-input"
                        placeholder="نام کاربری خود را وارد کنید"
                        onChange={this.updateOrigin}
                        autoFocus="autofocus"
                      />
                      <label
                        htmlFor="inputPassword"
                        className="login-input-label"
                      >
                        رمز عبور
                      </label>
                      <input
                        type="password"
                        id="inputPassword"
                        className="form-control login-input"
                        placeholder="رمز عبور خود را وارد کنید"
                        onChange={this.updatePass}
                      />
                      <div
                        className="login-error-box"
                        style={{
                          opacity:
                            this.state.loginErrorMessage === "" ? 0 : "1",
                        }}
                      >
                        {this.state.loginErrorMessage}
                      </div>
                      <button
                        className="btn btn-lg btn-block login-btn"
                        type="submit"
                        disabled={this.state.disableLoginBtn}
                      >
                        {this.state.disableLoginBtn
                          ? "لطفا صبر کنید"
                          : "ورود به حساب کاربری"}
                        <span
                          className="fal fa-sign-in"
                          style={{
                            marginLeft: "26px",
                          }}
                        />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <MainChatPanel
              adminData={this.state.adminData}
              message={this.state.message}
              ws={this.ws}
              handleOffline={this.handleOffline}
              isOffline={this.state.isOffline}
              connected={this.state.connected}
              socketConnect={this.socketConnect}
              {...this.props}
            />
            {this.state.serverAlert ? (
              <ServerAlert serverAlertMessage={this.state.serverAlertMessage} />
            ) : null}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
export default (props) => (
  <Context.Consumer>
    {(state) => (
      <Login
        {...props}
        addContact={state.addContact}
        contacts={state.contacts}
        updateContact={state.updateContact}
        removeContact={state.removeContact}
        setMessageAcknowledge={state.setMessageAcknowledge}
      />
    )}
  </Context.Consumer>
);
