import React, { Component } from 'react';
import { Context } from '../App';
import Select from 'react-select';

class Tag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showTagList: false,
            selectedOption: null,
            contactTags: [],
            addOrRemoveTagLoading: false
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    setWrapperRef = (node) => {
        this.wrapperRef = node;
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
        this.addTagToUser(selectedOption.value);
        console.log(`Option selected:`, selectedOption);
    }

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
          this.setState({showTagList: false});
        }
      }

    toggleTagList = () => {
        this.setState(prevState => ({
            showTagList: !prevState.showTagList
        }));
    };
    addTagToUser = tag_id => {
        this.setState({addOrRemoveTagLoading: true});
        let contactTags = this.props.getContactTags(
            this.props.telegram_id
        );
        if (contactTags.indexOf(parseInt(tag_id)) === -1) contactTags.push(parseInt(tag_id));
        this.setState({contactTags}, () => this.updateTagRequest(tag_id, 'add'));
    };
    removeTagFromUser = tag_id => {
        this.setState({addOrRemoveTagLoading: true});
        let contactTags = this.props.getContactTags(
            this.props.telegram_id
        );
        let tagIndex = contactTags.indexOf(parseInt(tag_id));
        if (tagIndex !== -1) contactTags.splice(tagIndex,1);
        this.setState({contactTags}, () => this.updateTagRequest(tag_id, 'remove'));

    };

    updateTagRequest = (tag_id, state) => {
        let contactTagsArray = this.state.contactTags;
        const axios = require('axios');
        let config = {
            headers: {
                username: sessionStorage.getItem('user'),
                password: sessionStorage.getItem('tk'),
                'Content-Type': 'application/json'
            }
        };
        axios
            .put(
                process.env.REACT_APP_RINGEL_URL + 'usertags/' +
                    this.props.telegram_id +
                    '/',
                contactTagsArray,
                config
            )
            .then(response => {
                console.log('add tag to users : ', response);
                if (state === 'add') this.props.addTagToContact(this.props.telegram_id, tag_id);
                else if (state === 'remove') this.props.removeTagFromContact(this.props.telegram_id, tag_id);
                this.setState({addOrRemoveTagLoading: false});
            })
            .catch(error => {
                this.setState({addOrRemoveTagLoading: true});
                console.log(error);
                let errorMessage;
                if (state === "add") errorMessage = " ثبت نشد."
                else if (state === "remove") errorMessage = " حذف نشد."
                alert(this.state.selectedOption.label + errorMessage);
                this.setState({addOrRemoveTagLoading: false});

            });
    };
    render() {
        let options = [];
        // const { selectedOption } = this.state;

        Object.keys(this.props.tags).map(
            tag_id => (
                options.push({label: this.props.tags[tag_id], value: tag_id })
            )
        )

        return (
            <React.Fragment>
                <div className='col-12 no-padding no-gutters'>
                    <div
                        className=''
                        style={{ marginTop: this.props.marginTop }}
                    >
                        <span>برچسب‌ها</span>
                        <div
                            className='tag-card-wrapper'
                            // style={{opacity: 0, visibility: "hidden"}}
                        >
                            {(this.props.getContactTags(this.props.telegram_id) && this.props.getContactTags(this.props.telegram_id)
                                .length !== 0) ? (
                                    <React.Fragment>
                                        {this.state.addOrRemoveTagLoading && (
                                            <div className="loader" />
                                        )}
                                        <div className='tag-card-top-wrapper justify-content-end'>
                                        {this.props
                                            .getContactTags(this.props.telegram_id)
                                            // {/* {this.state.contactTagsArray */}
                                            .map(tag_id => (
                                                <div
                                                    className='tag'
                                                    key={`user-tag#${tag_id}`}
                                                >
                                                    {this.props.idToTag(tag_id)}
                                                    <span
                                                        className='tag-remove-btn fal fa-times'
                                                        onClick={() => {
                                                            this.removeTagFromUser(
                                                                tag_id
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </React.Fragment>
                            ) : (
                                this.state.addOrRemoveTagLoading ? (
                                    <div className="loader" />
                                ) : (
                                    <p>
                                        برای دسترسی آسان‌تر به مخاطبین می‌توانید با
                                        استفاده از دکمه زیر برای آنها برچسب بگذارید
                                    </p>
                                )
                            )}
                            <div className='tag-card-bottom-wrapper'>
                                <div className='row h-100 no-gutters'>
                                    <div className='col-12 d-flex justify-content-start align-items-center'>
                                        <div
                                            style={{
                                                backgroundColor: `${
                                                    this.state.showTagList
                                                        ? '#fff'
                                                        : '#9c27b0'
                                                }`,
                                                color: `${
                                                    this.state.showTagList
                                                        ? '#9c929d'
                                                        : '#fff'
                                                }`
                                            }}
                                            className={`tag-add-btn fal ${
                                                this.state.showTagList
                                                    ? ' fa-times'
                                                    : 'fa-plus'
                                            }`}
                                            /* on={this.toggleTagList} */
                                            onClick={this.toggleTagList}
                                        />
                                        {/* <div
                                            className='tag-counter'
                                            style={{
                                                backgroundColor: `${
                                                    this.state.userTags.length
                                                        ? '#fbd6d6'
                                                        : '#f6f5f6'
                                                }`,
                                                color: `${
                                                    this.state.userTags.length
                                                        ? '#ee5353'
                                                        : '#9c929d'
                                                }`
                                            }}
                                        >
                                            <span>۳</span>
                                            <span> از </span>
                                            <span>
                                                {this.toPersianDigits(
                                                    this.state.userTags.length.toString()
                                                )}
                                            </span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {this.state.showTagList ? (
                                // <ul className='tag-list-drop-down' ref={this.setWrapperRef}>
                                //      {Object.keys(this.props.tags).map(
                                //         tag_id => (
                                //             <li
                                //                 key={`tag-drop-down#${tag_id}`}
                                //                 id={tag_id}
                                //                 onClick={() => {
                                //                     this.addTagToUser(tag_id);
                                //                     this.setState({
                                //                         showTagList: false
                                //                     });
                                //                 }}
                                //             >
                                //                 {this.props.tags[tag_id]}
                                //             </li>
                                //         )
                                //     )}
                                // </ul>
                                <div className='tag-list-drop-down' ref={this.setWrapperRef} style={{width: "150px"}}>

                                    <Select
                                        value={null}
                                        onChange={this.handleChange}
                                        options={options}
                                        placeholder="انتخاب کنید"
                                        isDisabled={this.state.addOrRemoveTagLoading}

                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default props => (
    <Context.Consumer>
        {state => (
            <Tag
                {...props}
                tags={state.tags}
                addTagToContact={state.addTagToContact}
                removeTagFromContact={state.removeTagFromContact}
                getContactTags={state.getContactTags}
                idToTag={state.idToTag}
                getTagCustomColor={state.getTagCustomColor}
            />
        )}
    </Context.Consumer>
);
