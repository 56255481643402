import React, { Component } from "react";
import Select from "react-select";

export default class FilterItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      value: 0,
      selectedOption: null,
      inputValue: "",
      selectedTags: [],
    };
  }

  toggleFilter = () => {
    if (this.props.options === "unread") {
      this.setState(
        (prevProps) => ({
          checked: !prevProps.checked,
          value: 1,
        }),
        () => {
          if (this.state.checked) {
            this.props.updateFilter(this.props.options, this.state.value);
          } else {
            this.props.updateFilter(this.props.options, 0);
          }
        }
      );
    } else {
      this.setState(
        (prevProps) => ({
          checked: !prevProps.checked,
        }),
        () => {
          if (this.state.checked) {
            this.props.updateFilter(this.props.options, this.state.value);
          } else {
            this.props.updateFilter(this.props.options, 0);
          }
        }
      );
    }
  };
  updateValue = (e) => {
    this.setState(
      {
        value: e.target.value,
      },
      () => {
        this.props.updateFilter(this.props.options, this.state.value);
      }
    );
  };

  updateMobile = (e) => {
    let convertedValue = this.convertToEn(e.target.value);
    this.setState(
      {
        value: convertedValue,
      },
      () => {
        this.props.updateFilter(this.props.options, this.state.value);
      }
    );
  };

  convertToEn = (val) => {
    var pn = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    var an = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    var cache = val;
    for (var i = 0; i < 10; i++) {
      var regex_fa = new RegExp(pn[i], "g");
      var regex_ar = new RegExp(an[i], "g");
      cache = cache.replace(regex_fa, i);
      cache = cache.replace(regex_ar, i);
    }
    return cache;
  };

  updateWeightDiffValue = (e) => {
    let weightDiff = this.convertToEn(e.target.value);
    if (e.target.type === "tel") {
      let ratio = document.getElementById("weight-diff-ratio");
      this.setState(
        {
          value: weightDiff * ratio.value,
        },
        () => {
          this.props.updateFilter(this.props.options, this.state.value);
        }
      );
    } else {
      let input = document.getElementById("weight-diff-input");
      this.setState(
        {
          value: e.target.value * input.value,
        },
        () => {
          this.props.updateFilter(this.props.options, this.state.value);
        }
      );
    }
  };
  updateDietStartDate = (e) => {
    this.props.updateFilter("dietStartDate", Number(e.target.value));
  };
  updateDietEndValue = (e) => {
    let endDate = this.convertToEn(e.target.value);
    if (e.target.type === "tel") {
      let toggleEnd = document.getElementById("diet-end-toggle");
      let startDate = document.getElementById("diet-start-date");
      if (startDate.value !== 1) {
        this.props.updateFilter("dietStartDate", 2);
      } else {
        this.props.updateFilter("dietStartDate", 1);
      }
      this.setState(
        {
          value: Number(endDate),
        },
        () => {
          if (toggleEnd.value === "issue") {
            this.props.updateFilter("dietIssue", this.state.value);
            this.props.updateFilter("dietEnd", 0);
          } else {
            this.props.updateFilter(
              "dietEnd",
              this.state.value * toggleEnd.value
            );
            this.props.updateFilter("dietIssue", 0);
          }
        }
      );
    } else {
      let toggleEnd = document.getElementById("diet-end-toggle");
      let startDate = document.getElementById("diet-start-date");
      console.log(startDate.value);
      this.props.updateFilter("dietStartDate", startDate.value);
      if (toggleEnd.value === "issue") {
        this.props.updateFilter("dietIssue", this.state.value);
        this.props.updateFilter("dietEnd", 0);
      } else {
        this.props.updateFilter("dietEnd", this.state.value * toggleEnd.value);
        this.props.updateFilter("dietIssue", 0);
      }
    }
  };

  updateCalCulatedhoobarTime = (e)=>{
    this.props.updateFilter("calculatedHoobarTime", Number(e.target.value));
  };
  updateDietCount = (e) => {
    // console.log(e.target.value);
    let dietCount = this.convertToEn(e.target.value);
    if (e.target.type === "tel") {
      let ratio = document.getElementById("diet-count-ratio");
      this.setState(
        {
          value: dietCount * ratio.value,
        },
        () => {
          this.props.updateFilter(this.props.options, this.state.value);
        }
      );
    } else {
      let input = document.getElementById("diet-count-input");
      this.setState(
        {
          value: e.target.value * input.value,
        },
        () => {
          this.props.updateFilter(this.props.options, this.state.value);
        }
      );
    }
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    this.addTagsTofilter(selectedOption);
    console.log(`Option selected:`, selectedOption);
  };

  addTagsTofilter = (value) => {
    let filteredTags = this.state.selectedTags;
    if (filteredTags.map((val) => val.value).indexOf(value.value) === -1) {
      filteredTags.push(value);
    }
    this.setState({ selectedTags: filteredTags }, () => {
      this.props.updateFilter(
        "tag",
        this.state.selectedTags.map((val) => val.value).join(",")
      );
    });
  };

  removeTagFromUser = (value) => {
    let filteredTags = this.state.selectedTags;
    let thisIndex = filteredTags.indexOf(value);
    filteredTags.splice(thisIndex, 1);
    this.setState({ selectedTags: filteredTags }, () => {
      this.props.updateFilter(
        "tag",
        this.state.selectedTags.map((val) => val.value).join(",")
      );
    });
  };

  renderOptions = () => {
    switch (this.props.options) {
      case "mobile":
        return (
          <div className="filter-option-wrapper" id="mobile-filter">
            <input
              type="tel"
              placeholder="عدد"
              id="mobile-filter-input"
              onChange={this.updateMobile}
            />
          </div>
        );
      case "dietStartDate":
        return (
          <div className="filter-option-wrapper" id="start-diet">
            <label
              htmlFor="start-diet-not-set"
              className="filter-option-label"
              onClick={this.updateValue}
            >
              <input
                id="start-diet-not-set"
                type="radio"
                name="start-diet"
                value={1}
              />
              <div className="filter-option-btn">ثبت نشده</div>
            </label>
            <label
              htmlFor="start-diet-set"
              className="filter-option-label"
              onClick={this.updateValue}
            >
              <input
                id="start-diet-set"
                type="radio"
                name="start-diet"
                value={2}
              />
              <div className="filter-option-btn">ثبت شده</div>
            </label>
          </div>
        );
      case "dietEnd":
        return (
          <div className="filter-option-wrapper d-block" id="diet-end">
            <span>براساس</span>
            <select
              name=""
              id="diet-start-date"
              onChange={this.updateDietStartDate}
            >
              <option value={2}>تاریخ شروع</option>
              <option value={1}>تاریخ صدور</option>
            </select>
            <span>افرادی که</span>
            {/*<select name="" id="diet-end-ratio" onChange={this.updateDietEndValue}>*/}
            {/*<option value={-1}>بیشتر از</option>*/}
            {/*<option value={1}>کمتر از</option>*/}
            {/*</select>*/}
            <input
              type="tel"
              placeholder=""
              id="diet-end-input"
              onChange={this.updateDietEndValue}
            />
            <span>روز</span>
            <select
              name=""
              id="diet-end-toggle"
              onChange={this.updateDietEndValue}
            >
              <option value={1}>از پایان دوره رژیم‌شان گذشته است</option>
              <option value={-1}>به پایان دوره رژیم باقیمانده است</option>
              <option value="issue">از شروع دوره رژیم‌شان گذشته است</option>
            </select>
          </div>
        );
      case "calculatedHoobarTime":
          return (
            <div className="filter-option-wrapper d-block" id="hoobar-end">
              <span>براساس</span>
              <select
                name=""
                id="hoobar-enter-date">
                <option value={1}>تاریخ ورود</option>
              </select>
              <span>افرادی که</span>
              <input
                type="tel"
                placeholder=""
                id="hoobar-end-input"
                onChange={this.updateCalCulatedhoobarTime}
              />
              <span>روز</span>
              <select
                name=""
                id="hoobar-end-toggle"
              >
                <option value={1}>از پایان دوره حامیشان باقیمانده است</option>
              </select>
            </div>
          );
      case "weightDifference":
        return (
          <div className="filter-option-wrapper" id="weight-diff">
            <select
              name=""
              id="weight-diff-ratio"
              onChange={this.updateWeightDiffValue}
            >
              <option value={-1}>بیشتر از</option>
              <option value={1}>کمتر از</option>
            </select>
            <input
              type="tel"
              placeholder="عدد به گرم"
              id="weight-diff-input"
              onChange={this.updateWeightDiffValue}
            />
          </div>
        );
      case "dietDuration":
        return (
          <div className="filter-option-wrapper d-block">
            <div className="row justify-content-center no-gutters no-padding">
              <div className="col-4 p-1">
                <label
                  htmlFor="day-7"
                  className="diet-day-label"
                  onClick={this.updateValue}
                >
                  <input type="radio" id="day-7" name="diet-days" value={7} />
                  <div className="diet-day-btn">۷ روزه</div>
                </label>
              </div>
              <div className="col-4 p-1">
                <label
                  htmlFor="day-10"
                  className="diet-day-label"
                  onClick={this.updateValue}
                >
                  <input type="radio" id="day-10" name="diet-days" value={10} />
                  <div className="diet-day-btn">۱۰ روزه</div>
                </label>
              </div>
              <div className="col-4 p-1">
                <label
                  htmlFor="day-12"
                  className="diet-day-label"
                  onClick={this.updateValue}
                >
                  <input type="radio" id="day-12" name="diet-days" value={12} />
                  <div className="diet-day-btn">۱۲ روزه</div>
                </label>
              </div>
            </div>
            <div className="row justify-content-center no-gutters no-padding">
              <div className="col-4 p-1">
                <label
                  htmlFor="day-14"
                  className="diet-day-label"
                  onClick={this.updateValue}
                >
                  <input type="radio" id="day-14" name="diet-days" value={14} />
                  <div className="diet-day-btn">۱۴ روزه</div>
                </label>
              </div>
              <div className="col-4 p-1">
                <label
                  htmlFor="day-21"
                  className="diet-day-label"
                  onClick={this.updateValue}
                >
                  <input type="radio" id="day-21" name="diet-days" value={21} />
                  <div className="diet-day-btn">۲۱ روزه</div>
                </label>
              </div>
              <div className="col-4 p-1">
                <label
                  htmlFor="day-30"
                  className="diet-day-label"
                  onClick={this.updateValue}
                >
                  <input type="radio" id="day-30" name="diet-days" value={30} />
                  <div className="diet-day-btn">۳۰ روزه</div>
                </label>
              </div>
            </div>
            <div className="row justify-content-center no-gutters no-padding">
              <div className="col-4 p-1">
                <label
                  htmlFor="day-45"
                  className="diet-day-label"
                  onClick={this.updateValue}
                >
                  <input type="radio" id="day-45" name="diet-days" value={45} />
                  <div className="diet-day-btn">۴۵ روزه</div>
                </label>
              </div>
              <div className="col-4 p-1">
                <label
                  htmlFor="day-100"
                  className="diet-day-label"
                  onClick={this.updateValue}
                >
                  <input
                    type="radio"
                    id="day-100"
                    name="diet-days"
                    value={100}
                  />
                  <div className="diet-day-btn">۱۰۰ روزه</div>
                </label>
              </div>
              <div className="col-4 p-1">
                <label
                  htmlFor="day-180"
                  className="diet-day-label"
                  onClick={this.updateValue}
                >
                  <input
                    type="radio"
                    id="day-180"
                    name="diet-days"
                    value={180}
                  />
                  <div className="diet-day-btn">۱۸۰ روزه</div>
                </label>
              </div>
            </div>
          </div>
        );
      case "dietCount":
        return (
          <div className="filter-option-wrapper" id="diet-count">
            <select
              name=""
              id="diet-count-ratio"
              onChange={this.updateDietCount}
            >
              <option value={-1}>بیشتر از</option>
              <option value={1}>کمتر از</option>
            </select>
            <input
              type="tel"
              placeholder="عدد"
              id="diet-count-input"
              onChange={this.updateDietCount}
            />
          </div>
        );
      case "gender":
        return (
          <div className="filter-option-wrapper" id="">
            <label
              htmlFor="gender-male"
              className="filter-option-label"
              onClick={this.updateValue}
            >
              <input id="gender-male" type="radio" value={1} name="gender" />
              <div className="filter-option-btn">مرد</div>
            </label>
            <label
              htmlFor="gender-female"
              className="filter-option-label"
              onClick={this.updateValue}
            >
              <input id="gender-female" type="radio" value={2} name="gender" />
              <div className="filter-option-btn">زن</div>
            </label>
          </div>
        );
      case "tag":
        return (
          <div
            className="filter-option-wrapper tags-filter"
            id="tag"
            style={{ flexDirection: "column" }}
          >
            <div className="tag-card-top-wrapper justify-content-start">
              {this.state.selectedTags.map((tag) => (
                <div className="tag" key={tag}>
                  {tag.label}
                  <span
                    className="tag-remove-btn fal fa-times"
                    onClick={() => {
                      this.removeTagFromUser(tag);
                    }}
                  />
                </div>
              ))}
            </div>
            <Select
              value={null}
              classNamePrefix="filter"
              className="filter-tags-select"
              onChange={this.handleChange}
              options={this.props.tags}
              placeholder="انتخاب کنید"
            />
          </div>
        );
      default:
        return null;
    }
  };
  render() {
    return (
      <React.Fragment>
        <div
          className="filter"
          style={
            this.state.checked ? { maxHeight: "400px" } : { maxHeight: "38px" }
          }
        >
          <div className="filter-top-wrapper" onClick={this.toggleFilter}>
            <div className="filter-name">{this.props.filterName}</div>
            <div className="filter-toggle-btn">
              <label className="filter-switch m-0">
                <input
                  type="checkbox"
                  checked={this.state.checked}
                  onChange={this.toggleFilter}
                />
                {this.state.checked ? (
                  <div
                    className="filter-slider round"
                    style={{ color: "#9c27b0" }}
                  />
                ) : (
                  <div
                    className="filter-slider round"
                    style={{ color: "#eadcef" }}
                  />
                )}
              </label>
            </div>
          </div>
          {this.renderOptions()}
        </div>
      </React.Fragment>
    );
  }
}