import PersianDate from 'persian-date';

export function last_chat_time(time) {
    // timestamp
    let persianDate;
    let date = new PersianDate(time);
    let dateDiff = Date.now() - time;
    if (dateDiff > 86400000) {
        // more than 1 day
        if (dateDiff > 604800000) {
            persianDate = date.format('D MMMM'); // more than 1 week
        } else {
            persianDate = date.format('dddd'); // 1 week > > 1 day
        }
    } else {
        let today = new PersianDate(Date.now());
        if (today.format('dddd') !== date.format('dddd')) {
            persianDate = date.format('dddd');
        } else {
            persianDate = date.format('H:mm');
        }
    }
    return persianDate;
}
export function to_persian_digits(number) {
    let id = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
    return number.replace(/[0-9]/g, function(w) {
        return id[+w];
    });
}
