import React, { useState, useEffect, useRef } from "react";
import { Context } from "../App";
import { useMediaPredicate } from "react-media-hook";

function DataProvider(props) {
  const callbackFunc = useRef(null);
  const callbackFlag = useRef(false);

  const xs = useMediaPredicate("(max-width:575px)");
  const sm = useMediaPredicate("(min-width:576px)");
  const md = useMediaPredicate("(min-width:768px)");
  const lg = useMediaPredicate("(min-width:992px)");
  const xl = useMediaPredicate("(min-width:1200px)");

  const [contacts, setContacts] = useState([]);
  const [tags, setTags] = useState({});
  const [tagsObj, setTagsObj] = useState({});
  const [messageAcknowledge, setMessageAcknowledge] = useState({});

  const find_contact_index_by_telegram_id = (telegram_id) => {
    return contacts.findIndex((contact) => {
      return contact.telegram_id === telegram_id;
    });
  };
  const addContact = (contact) => {
    find_contact_index_by_telegram_id(contact.telegram_id) === -1 &&
      setContacts((prev) => {
        if (contact.from !== undefined) {
          return [contact, ...prev];
        } else {
          return [...prev, contact];
        }
      });
  };
  const updateContact = (contacts) => {
    setContacts(contacts);
  };

  const removeContact = (contact) => {
    let index = find_contact_index_by_telegram_id(contact.telegram_id);
    if (index !== -1) {
      setContacts((prev) => {
        let t = [...prev];
        t.splice(index, 1);
        return t;
      });
    }
  };

  const addTag = (tag) => {
    setTags((prev) => {
      return { ...prev, [tag.id]: tag.explanations };
    });
    setTagsObj((prev) => {
      return { ...prev, [tag.id]: tag };
    });
  };
  const addTagToContact = (telegram_id, tag_id) => {
    let index = find_contact_index_by_telegram_id(telegram_id);
    tag_id = parseInt(tag_id);
    if (index !== -1 && !contacts[index].tags.includes(tag_id)) {
      setContacts((prev) => {
        let t = [...prev];
        t[index].tags.push(tag_id);
        return t;
      });
      // callbackFunc.current = callback;
      // callbackFlag.current = true;
    }
  };
  const removeTagFromContact = (telegram_id, tag_id) => {
    let index = find_contact_index_by_telegram_id(telegram_id);
    tag_id = parseInt(tag_id);
    if (index !== -1) {
      setContacts((prev) => {
        let t = [...prev];
        t[index].tags = t[index].tags.filter((tag) => {
          return tag !== tag_id;
        });
        return t;
      });
      // callbackFunc.current = callback;
      // callbackFlag.current = true;
    }
  };
  const getContactTags = (telegram_id) => {
    let index = find_contact_index_by_telegram_id(telegram_id);
    if (index !== -1) {
      return [...contacts[index].tags];
    } else {
      return undefined;
    }
  };
  const idToTag = (id) => {
    return tags[id];
  };
  const getTagCustomColor = (id) => {
    if(typeof tagsObj[id] != 'undefined') {
      return tagsObj[id].customColor;
    }
    return null;
  };
  const getContact = (telegram_id) => {
    let index = find_contact_index_by_telegram_id(telegram_id);
    if (index !== -1) {
      return contacts[index];
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    if (callbackFlag.current) {
      callbackFunc.current();
      callbackFlag.current = false;
    }
  }, [contacts]);

  return (
    <Context.Provider
      value={{
        contacts: contacts,
        addContact: addContact,
        removeContact: removeContact,
        tags: tags,
        addTag: addTag,
        updateContact: updateContact,
        addTagToContact: addTagToContact,
        removeTagFromContact: removeTagFromContact,
        getContactTags: getContactTags,
        idToTag: idToTag,
        getTagCustomColor: getTagCustomColor,
        getContact: getContact,
        messageAcknowledge: messageAcknowledge,
        setMessageAcknowledge: setMessageAcknowledge,
        xs: xs,
        sm: sm,
        md: md,
        lg: lg,
        xl: xl,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}
export default DataProvider;
