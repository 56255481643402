import React, { Component, useState, useEffect } from 'react';
import PersianDate from 'persian-date';

import 'react-persian-calendar-date-picker/lib/DatePicker.css';
import DatePicker from 'react-persian-calendar-date-picker';

let switch_calendar = null;

const StartDietDate = ({ healthData, fileNumber, minimumDate }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [showCalendar, setShowCalendar] = useState(false);

    const toggleCalendar = () => {
        setShowCalendar(prev => !prev);
        switch_calendar();
    };

    const onChangeDate = date => {
        toggleCalendar();
        setSelectedDate(date);
    };

    const changeDietStart = () => {
        if (selectedDate !== null) {
            let timestampDate = new PersianDate([
                selectedDate.year,
                selectedDate.month,
                selectedDate.day
            ]);
            const axios = require('axios');
            axios
                .get(
                    process.env.REACT_APP_LIMOOME_URL + 'setstartdate/sdfghjklkjhgf6789cvbnm12233mnbvcvbn?profile=' +
                        fileNumber +
                        '&startdate=' +
                        timestampDate
                )
                .then(response => {
                    console.log(response.data);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    const maximumDate = () => {
        let retrunformat = date => {
            return {
                year: date.year(),
                month: date.month(),
                day: date.date()
            };
        };

        if (minimumDate.year < 1390) {
            return {
                year: 1420,
                month: 1,
                day: 1,
            }
        } else {
            let tempDate = new PersianDate([
                minimumDate.year,
                minimumDate.month,
                minimumDate.day
            ]);
            let _21dayAfter = new PersianDate(tempDate.valueOf() + 1814400000);
            return retrunformat(_21dayAfter);
        }

        // if (getToday().year < _21dayAfter.year) {
        //     return getToday();
        // } else if (getToday().year > _21dayAfter.year) {
        //     return retrunformat(_21dayAfter);
        // } else {
        //     if (getToday().month < _21dayAfter.month) {
        //         return getToday();
        //     } else if (getToday().month > _21dayAfter.month) {
        //         return retrunformat(_21dayAfter);
        //     } else {
        //         if (getToday().day < _21dayAfter.day) {
        //             return getToday();
        //         } else if (getToday().day > _21dayAfter.day) {
        //             return retrunformat(_21dayAfter);
        //         } else {
        //             return getToday();
        //         }
        //     }
        // }
    };

    useEffect(() => {
        changeDietStart();
    }, [selectedDate]);

    return (
        <>
            {healthData.farsi_name !== undefined &&
            healthData.farsi_name !== '' &&
            healthData.farsi_name !== '  ' ? (
                <div className='rtl health-data-row'>
                    <span className='health-data-name rtl'>
                        {healthData.farsi_name + ":"}
                    </span>
                    <span className='health-data-value'>
                        {selectedDate !== null
                            ? `${selectedDate.day} / ${selectedDate.month} / ${selectedDate.year}`
                            : healthData.value !== undefined
                            ? healthData.value
                            : '--'}
                    </span>
                    <span
                        className={`fal ${
                            showCalendar ? 'fa-times' : 'fa-calendar-plus'
                        } change-diet-date-icon`}
                        onClick={toggleCalendar}
                    />
                </div>
            ) : null}
            <div className='diet-date-picker-wrapper'>
                <DatePicker
                    onChange={onChangeDate}
                    selectedDay={selectedDate}
                    minimumDate={minimumDate}
                    maximumDate={maximumDate()}
                    renderInput={props => {
                        switch_calendar = props.onFocus;
                        return <div {...props}></div>;
                    }}
                />
            </div>
        </>
    );
};

class HealthData extends Component {
    render() {
        const {healthData, fileNumber, minimumDate} = this.props;
        return (
            <React.Fragment>
                {healthData.farsi_name === 'تاریخ شروع' ? (
                    <StartDietDate
                        healthData={healthData}
                        fileNumber={fileNumber}
                        minimumDate={minimumDate}
                    />
                ) : (
                    <React.Fragment>
                        {healthData.farsi_name !== undefined &&
                        healthData.farsi_name !== '' &&
                        healthData.farsi_name !== '  ' ? (
                            <div className='rtl health-data-row'>
                                <span className='health-data-name rtl'>
                                    {healthData.farsi_name + ":"}
                                </span>
                                <span className='health-data-value'>
                                    {healthData.value}
                                </span>
                            </div>
                        ) : null}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}
export default HealthData;
