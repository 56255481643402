import React, { Component } from 'react';

import ChartComponent from '../components/ChartComponent';
// import SupporterReportCard from "../components/SupporterReportCard";
import DailyReport from './DailyReport';

class SupervisorChart extends Component {
  constructor(props) {
    super(props);
    this.showSupporterPage = this.showSupporterPage.bind(this);
  }
  componentDidMount() {
    // this.getAllSupporters();
    // this.getChartData();
  }
  
  showSupporterPage() {
    switch (this.props.page) {
      case 'message-charts':
        return <ChartComponent />;
      case 'supporter-daily-report':
        return <DailyReport />;
      default: return null;
    }
  }
  render() {
    return (
      <React.Fragment>
        {this.showSupporterPage()}
        {/* <div className="right-side-menu">
                    <ul className="side-menu">
                        <li className="fal fa-user-headset selected" id="supporter-daily-report" onClick={this.selectMenu}></li>
                        <li className="fal fa-chart-bar" id="message-charts" onClick={this.selectMenu}></li>
                        <li className="fal fa-table" onClick={this.selectMenu}></li>
                        <li className="fal fa-users" onClick={this.selectMenu}></li>
                    </ul>
                </div> */}
      </React.Fragment>
    );
  }
}
export default SupervisorChart;
