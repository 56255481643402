import React, {Component} from 'react';
import {Route} from "react-router-dom";

import "./style/css/bootstrap.min.css";
import "./style/css/bootstrap-rtl.min.css";
import "./style/css/plyr.css"
import "./style/css/main.css";

import DataProvider from './components/DataProvider';

import Login from "./pages/Login";

export const Context = React.createContext();
class App extends Component{
  render(){
    return(
        <DataProvider>
            <React.Fragment>
              <Route path={"/"} exact={true} render={(props) => <Login {...props} />}/>
            </React.Fragment>
        </DataProvider>
    );
  }
}

export default App;
