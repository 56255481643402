import React, { Component } from "react";
import ChatMessage from "../components/ChatMessage";
// import vmsg from 'vmsg';
import { ReactComponent as SendButton } from "../icons/arrow.svg";
import Plyr from "plyr";
import PersianDate from "persian-date";
import { Context } from "../App";
import UserImage from "../style/img/user_image.png";
import MicRecorder from "mic-recorder-to-mp3";

const Mp3Recorder = new MicRecorder({ bitRate: 64 });

// const recorder = new vmsg.Recorder({
//     wasmURL: 'https://unpkg.com/vmsg@0.3.0/vmsg.wasm'
// });
class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elements: [],
      historyLength: -1,
      message: null,
      pageIndex: 0,
      isLoading: false,
      isRecording: false,
      recordProcess: false,
      recordings: [],
      showDate: false,
      scrollJump: true,
      recordStartTime: null,
      seconds: "--",
      minutes: "--",
      playAudio: false,
      progressValue: 0,
      saleLink: "",
      timeInterval: null,
      uploadProgress: 0,
      isBlocked: false,
    };
  }
  componentDidMount() {
    this.loadHistory();
    document.getElementById("chat-area").addEventListener("play", (event) => {
      let temp = document.getElementsByTagName("audio");
      for (let i = 0; i < temp.length; i++) {
        if (temp[i] !== event.target.children[1]) {
          temp[i].pause();
        }
      }
    });
    navigator.mediaDevices.getUserMedia(
      { audio: true },
      () => {
        console.log("Permission Granted");
        this.setState({ isBlocked: false });
      },
      () => {
        console.log("Permission Denied");
        this.setState({ isBlocked: true });
      }
    );
  }

  start = () => {
    this.setState({ isLoading: true });
    if (this.state.isBlocked) {
      console.log("Permission Denied");
    } else {
      if (!this.state.isRecording) {
        this.setState({ recordings: [] }, () => {
          Mp3Recorder.start()
            .then(() => {
              this.setState(
                {
                  isLoading: false,
                  isRecording: true,
                  recordStartTime: Date.now() / 1000,
                  recordProcess: true,
                },
                () => {
                  this.recordCounter();
                }
              );
            })
            .catch((e) => {
              console.error(e);
              this.setState({ isLoading: false });
            });
        });
      } else {
        Mp3Recorder.stop()
          .getMp3()
          .then(([buffer, blob]) => {
            clearInterval(this.state.timeInterval);
            const file = new File(buffer, "me-at-thevoice.mp3", {
              type: blob.type,
              lastModified: Date.now(),
            });
            this.setState({
              isLoading: false,
              isRecording: false,
              seconds: "--",
              minutes: "--",
              recordings: this.state.recordings.concat(blob),
            });
          })
          .catch((e) => console.log(e));
      }
    }
  };
  upload = (fileType) => {
    let cancel, text, tag;
    let file = new FormData();

    if (fileType === "voice") {
      text = "avoice/a.c";
      tag = "supporter-voice";
      file.append("file", this.state.recordings[0], "test.oga");
    } else {
      let blobFile = document.getElementById("file-upload").files[0];
      text = URL.createObjectURL(blobFile);
      tag = "supporter-photo";
      file.append("file", blobFile);
    }

    this.setState(
      (prev) => {
        let t = prev.elements;
        t.push({
          text: text,
          tag: tag,
          hasProgress: true,
          date: new PersianDate().valueOf(),
          cancelUpload: async () => {
            await cancel();
            fileType === "voice" && (await this.cancelVoice());
            this.setState((prev) => {
              let t = prev.elements;
              t.pop();
              return {
                elements: t,
              };
            });
          },
        });
        return {
          elements: t,
        };
      },
      () => {
        let chatArea = document.getElementById("chat-area");
        chatArea.scrollTop = chatArea.scrollHeight;
      }
    );

    const axios = require("axios");

    let config = {
      processData: false,
      contentType: false,
    };

    let user = sessionStorage.getItem("user");
    let tk = sessionStorage.getItem("tk");

    axios({
      method: "post",
      url:
        process.env.REACT_APP_RINGEL_URL +
        "upload/" +
        user +
        "/" +
        this.props.from +
        "/?password=" +
        tk,
      data: file,
      config: config,
      onUploadProgress: (progressEvent) => {
        this.setState({
          uploadProgress: Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          ),
        });
      },
      cancelToken: new axios.CancelToken(function executor(c) {
        cancel = c;
      }),
    })
      .then((response) => {
        console.log(response);

        if (document.getElementById("file-upload") != null) {
          document.getElementById("file-upload").value = "";
        }

        if (response.status === 200) {
          this.setState((prev) => {
            let elements = prev.elements;
            fileType === "voice" &&
              (elements[elements.length - 1].text = response.data);
            elements[elements.length - 1].hasProgress = false;
            return {
              elements: elements,
              uploadProgress: 0,
            };
          });
          fileType === "voice" &&
            this.setState({
              recordProcess: false,
            });
        }
      })
      .catch((error) => {
        console.log(error);

        if (document.getElementById("file-upload") != null) {
          document.getElementById("file-upload").value = "";
        }
      });
  };
  recordCounter = () => {
    let recordStartTime = this.state.recordStartTime;
    this.setState({
      timeInterval: setInterval(() => {
        let timeDiff = Date.now() / 1000 - recordStartTime;
        let seconds = Math.floor(timeDiff % 60);
        let minutes = Math.floor(timeDiff / 60);
        this.setState({
          seconds: this.toPersianDigits(seconds.toString()),
          minutes: this.toPersianDigits(minutes.toString()),
        });
      }, 1000),
    });
  };
  toPersianDigits = (number) => {
    let id = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    return number.replace(/[0-9]/g, function (w) {
      return id[+w];
    });
  };
  loadHistory = () => {
    const axios = require("axios");
    axios
      .get(
        process.env.REACT_APP_RINGEL_URL +
          "getchats/" +
          this.props.from +
          "?pageindex=" +
          this.state.pageIndex +
          "&pagesize=" +
          50
      )
      .then((response) => {
        console.log(response);
        let chatHistory = this.chatHistoryFunc(response);
        if (chatHistory !== null) {
          let elements = this.state.elements;
          Array.prototype.push.apply(chatHistory, elements);
          let chatArea = document.getElementById("chat-area");
          let lastScrollHeight = chatArea.scrollHeight;
          this.setState(
            {
              elements: chatHistory,
              historyLength: response.data.length,
            },
            () => {
              // let last = document.getElementById("chat-area").firstChild;
              // console.log(last.offsetTop);

              if (this.state.scrollJump) {
                chatArea.scrollTop = chatArea.scrollHeight;
                setTimeout(function () {
                  chatArea.scrollTop = chatArea.scrollHeight;
                }, 1);
              } else {
                chatArea.scrollTop = chatArea.scrollHeight - lastScrollHeight;
                setTimeout(function () {
                  chatArea.scrollTop = chatArea.scrollHeight - lastScrollHeight;
                }, 1);
              }
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  chatHistoryFunc = (response) => {
    let chatHistory = [];
    for (let i = 0; i < response.data.length; i++) {
      if (response.data[i].userMessageText !== null) {
        let day = new Date(response.data[i].userMessageDate);
        let nextDay;
        let showDate;
        if (i !== response.data.length - 1) {
          if (response.data[i + 1].userMessageDate !== null) {
            nextDay = new Date(response.data[i + 1].userMessageDate);
          } else {
            nextDay = new Date(response.data[i + 1].suppAnswerDate);
          }
          showDate = day.toLocaleDateString() !== nextDay.toLocaleDateString();
        } else {
          showDate = false;
        }
        switch (response.data[i].operationNumber) {
          case "14":
            chatHistory.push({
              text: response.data[i].userMessageText,
              date: response.data[i].userMessageDate,
              showDate: showDate,
              tag: "user-voice",
            });
            break;
          case "19":
            chatHistory.push({
              text: response.data[i].userMessageText,
              date: response.data[i].userMessageDate,
              showDate: showDate,
              tag: "user-photo",
            });
            break;
          case "20":
            chatHistory.push({
              text: response.data[i].userMessageText,
              date: response.data[i].userMessageDate,
              showDate: showDate,
              tag: "user-video",
            });
            break;
          case "21":
            chatHistory.push({
              text: response.data[i].userMessageText,
              date: response.data[i].userMessageDate,
              showDate: showDate,
              tag: "user-file",
            });
            break;
          case "5":
            chatHistory.push({
              text: response.data[i].userMessageText,
              date: response.data[i].userMessageDate,
              showDate: showDate,
              tag: response.data[i].userMessageText.includes("http")
                ? "link"
                : "user",
            });
            break;
          case "12":
            chatHistory.push({
              text: "* " + response.data[i].userMessageText + " *",
              date: response.data[i].userMessageDate,
              showDate: showDate,
              tag: "user",
            });
            break;
          case "13":
            break;
          default:
            chatHistory.push({
              text: "***UNHANDLED OPERATION***",
              date: response.data[i].userMessageDate,
              showDate: showDate,
              tag: "user",
            });
        }
      } else {
        let day = new Date(response.data[i].suppAnswerDate);
        let nextDay;
        let showDate;
        if (i !== response.data.length - 1) {
          if (response.data[i + 1].suppAnswerDate !== null) {
            nextDay = new Date(response.data[i + 1].suppAnswerDate);
          } else {
            nextDay = new Date(response.data[i + 1].userMessageDate);
          }
          showDate = day.toLocaleDateString() !== nextDay.toLocaleDateString();
        } else {
          showDate = false;
        }
        switch (response.data[i].operationNumber) {
          case "18":
            chatHistory.push({
              text: response.data[i].suppMessageText,
              date: response.data[i].suppAnswerDate,
              showDate: showDate,
              tag: "supporter-voice",
            });
            break;
          case "21":
            chatHistory.push({
              text: response.data[i].suppMessageText,
              date: response.data[i].suppAnswerDate,
              showDate: showDate,
              tag: "supporter-file",
            });
            break;
          case "24":
            chatHistory.push({
              text: response.data[i].suppMessageText,
              date: response.data[i].suppAnswerDate,
              showDate: showDate,
              tag: "supporter-video",
            });
            break;
          case "5":
            chatHistory.push({
              text: response.data[i].suppMessageText,
              date: response.data[i].suppAnswerDate,
              showDate: showDate,
              tag: response.data[i].suppMessageText.includes("http")
                ? "supporter-link"
                : "self",
              status: "sentBefore",
            });
            break;
          case "13":
            break;
          default:
            chatHistory.push({
              text: "***UNHANDLED OPERATION***",
              date: response.data[i].suppAnswerDate,
              showDate: showDate,
              tag: "self",
            });
        }
      }
    }
    return chatHistory.reverse();
  };
  componentDidUpdate(prevProps) {
    if (this.props.from !== prevProps.from) {
      this.setState(
        {
          elements: [],
          historyLength: -1,
          message: null,
          pageIndex: 0,
          isLoading: false,
          isRecording: false,
          recordProcess: false,
          recordings: [],
          showDate: false,
          scrollJump: true,
          recordStartTime: null,
          seconds: "--",
          minutes: "--",
          playAudio: false,
          progressValue: 0,
          saleLink: "",
          timeInterval: null,
          uploadProgress: 0,
        },
        this.loadHistory
      );
    }
    if (
      this.props.message !== prevProps.message &&
      this.props.message.from === this.props.from
    ) {
      this.setState(
        (prev) => {
          let elements = [...prev.elements];
          elements.push(this.props.message);
          return {
            elements: elements,
          };
        },
        () => {
          let chatArea = document.getElementById("chat-area");
          chatArea.scrollTop = chatArea.scrollHeight;
        }
      );
    }
    if (
      typeof this.props.messageAcknowledge !== 'undefined' &&
      this.props.messageAcknowledge &&
      Object.keys(this.props.messageAcknowledge).length > 0
    ) {
      if (this.state.elements && this.state.elements.length > 0) {
        let elements = this.state.elements;

        let needUpdateState = false;
        elements.map((element) => {
          if (
            typeof this.props.messageAcknowledge !== 'undefined' &&
            typeof this.props.messageAcknowledge.timestamp !== 'undefined' &&
            element.date == this.props.messageAcknowledge.timestamp &&
            element.status == "sending"
          ) {
            if (this.props.messageAcknowledge.operationNumber == "26") {
              element.status = "sent";
              needUpdateState = true;
            } else if (this.props.messageAcknowledge.operationNumber == "27") {
              element.status = "failed";
              needUpdateState = true;
            }
          }
          return element;
        });

        if (needUpdateState) {
          this.setState({
            elements: elements,
          });
        }
      }
    }
  }
  sendMessage = () => {
    let from = sessionStorage.getItem("from");
    let timestamp = Date.now();

    if (this.state.message !== null && this.state.message !== "") {
      // console.log(this.state.message+","+","+)
            this.props.ws.send(
        JSON.stringify({
          message: this.state.message,
          from: from,
          to: this.props.from.toString(),
          operationNumber: "5",
          timestamp: timestamp,
        })
      );
      let elements = this.state.elements;
      elements.push({
        text: this.state.message,
        date: timestamp,
        tag: "supporter",
        status: "sending",
      });
      this.setState(
        {
          elements: elements,
          message: null,
        },
        () => {
          let chatArea = document.getElementById("chat-area");
          chatArea.scrollTop = chatArea.scrollHeight;
        }
      );
      document.getElementById("message-input").value = "";
    }
  };
  updateMessage = (event) => {
    let textarea = event.target;
    let maxHeight = 180;
    textarea.style.height = "38px";
    textarea.style.height = Math.min(textarea.scrollHeight, maxHeight) + "px";
    this.setState({
      message: event.target.value,
    });
    let chatArea = document.getElementById("chat-area");
    let chatInput = document.getElementById("chat-input-outer");
    chatArea.style.height = `calc(100% - ${chatInput.clientHeight}px - 24px)`;
    if (
      chatArea.scrollTop <=
        chatArea.scrollHeight - chatArea.clientHeight + 50 &&
      chatArea.scrollTop >= chatArea.scrollHeight - chatArea.clientHeight - 50
    ) {
      setTimeout(function () {
        chatArea.scrollTop = chatArea.scrollHeight;
      }, 1);
    }
  };
  loadMore = () => {
    let element = document.getElementById("chat-area");
    if (element.scrollTop === 0 && this.state.historyLength % 50 === 0) {
      let prevPageIndex = this.state.pageIndex;
      this.setState(
        {
          pageIndex: prevPageIndex + 1,
          scrollJump: false,
        },
        () => {
          this.loadHistory();
        }
      );
    }
  };
  togglePlayAudio = () => {
    this.setState(
      (prevState) => ({
        playAudio: !prevState.playAudio,
      }),
      () => {
        if (this.state.playAudio) {
          document.getElementById("player").play();
        } else {
          document.getElementById("player").pause();
        }
      }
    );
  };
  cancelVoice = async () => {
    clearInterval(this.state.timeInterval);
    try {
      await Mp3Recorder.stop();
    } catch (e) {
      console.log("cancelVoice: ", e);
    }
    this.setState({
      recordProcess: false,
      isRecording: false,
      isLoading: false,
      seconds: "--",
      minutes: "--",
    });
  };

  // cancelVoice = async () => {
  //     clearInterval(this.state.timeInterval);
  //     try {
  //         await recorder.stopRecording();
  //     } catch (e) {
  //         console.log('cancelVoice: ', e);
  //     }
  //     this.setState({
  //         recordProcess: false,
  //         isRecording: false,
  //         isLoading: false,
  //         seconds: '--',
  //         minutes: '--'
  //     });
  // };
  render() {
    const { isLoading, isRecording } = this.state;

    if (this.state.recordProcess) {
      if (!isRecording) {
        setTimeout(() => {
          new Plyr("#player", {
            settings: [],
            controls: ["play", "progress"],
            volume: 0.9,
            tooltips: { controls: false, seek: false },
          });
        }, 500);
      }
    }

    return (
      <React.Fragment>
        <div className="h-100 chat-page ltr">
          <div className="row row-height h-100 justify-content-center position-relative">
            {!this.props.lg ? (
              <div className="chat-navbar">
                {!this.props.md ? (
                  <div className="d-flex">
                    <span
                      className="fal fa-arrow-left m-auto"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={this.props.changeSection}
                    />
                  </div>
                ) : null}
                <div
                  className="rtl d-flex cursor-pointer"
                  style={{ flex: "0 0 80%" }}
                  onClick={this.props.toggleShowChatData}
                >
                  <img
                    className="d-block mr-2 cursor-pointer"
                    src={UserImage}
                    alt="User"
                    style={{
                      height: "100%",
                    }}
                  />
                  <div className="rtl user-name-wrapper cursor-pointer">
                    {this.props.getContact(this.props.from).name}{this.props.from}
                  </div>
                </div>
              </div>
            ) : null}
            <div
              className="col-12 chat-scrollable d-flex flex-column"
              id="chat-area"
              onScroll={this.loadMore}
            >
              {this.state.elements.length !== 0
                ? this.state.elements.map((message, index) => {
                    return (
                      <ChatMessage
                        message={message}
                        key={message.date+index}
                        status={message.status}
                        uploadProgress={
                          message.hasProgress ? this.state.uploadProgress : null
                        }
                      />
                    );
                  })
                : null}
            </div>
            <div className="chat-page-bottom-wrapper">
              <div className="input-group" id="chat-input-outer">
                {(this.state.message === null || this.state.message === "") &&
                this.state.recordProcess &&
                this.state.isRecording ? (
                  <div className="audio-recorder-stop-wrapper">
                    <button
                      className="record-btn fal audio-recorder-stop"
                      type="button"
                      disabled={isLoading}
                      onClick={this.start}
                    ></button>
                  </div>
                ) : (this.state.message === null ||
                    this.state.message === "") &&
                  this.state.recordProcess &&
                  !this.state.isRecording ? (
                  <div>
                    <button
                      className="send-message-btn"
                      type="button"
                      disabled={isLoading}
                      onClick={() => {
                        this.upload("voice");
                      }}
                    >
                      <SendButton className="send-arrow-button" />
                    </button>
                  </div>
                ) : (this.state.message === null ||
                    this.state.message === "") &&
                  !this.state.recordProcess ? (
                  <div className="h-100">
                    <button
                      className="record-btn h-100 fal fa-microphone"
                      type="button"
                      disabled={isLoading}
                      onClick={this.start}
                    ></button>
                  </div>
                ) : (
                  <button
                    className="send-message-btn"
                    type="button"
                    id="button-addon2"
                    onClick={this.sendMessage}
                  >
                    <SendButton className="send-arrow-button" />
                  </button>
                )}
                {this.state.recordProcess ? (
                  isRecording ? (
                    <div className="recording-progress">
                      <div className="recording-timer">
                        {this.state.minutes.length === 1
                          ? "۰" + this.state.minutes
                          : this.state.minutes}
                        :
                        {this.state.seconds.length === 1
                          ? "۰" + this.state.seconds
                          : this.state.seconds}
                      </div>
                      <div className="recording-text">
                        <span>درحال ضبط</span>
                        <span className="recording-indicator" />
                      </div>
                    </div>
                  ) : (
                    <div className="recording-progress">
                      <audio
                        id="player"
                        onEnded={this.togglePlayAudio}
                        src={window.URL.createObjectURL(
                          this.state.recordings[0]
                        )}
                      />
                      {/*<span id="seek-obj-container">*/}
                      {/*<progress id="seek-obj" value="0" max="1" />*/}
                      {/*</span>*/}
                      {/* <div className={`fal audio-play-btn ${this.state.playAudio ? 'fa-pause' : 'fa-play'}`} onClick={this.togglePlayAudio}/> */}
                    </div>
                  )
                ) : (
                  <textarea
                    style={{ height: "38px" }}
                    className="form-control rtl"
                    id="message-input"
                    placeholder="متن را اینجا بنویسید"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    autoFocus={true}
                    onChange={this.updateMessage}
                    onKeyUp={(event) => {
                      if (event.keyCode === 13 && event.shiftKey) {
                        event.preventDefault();
                      } else {
                        if (event.keyCode === 13) {
                          this.sendMessage();
                        }
                      }
                    }}
                  />
                )}
                <div style={{ display: "flex" }}>
                  {this.state.recordProcess ? (
                    this.state.isRecording ? (
                      <button
                        className="record-btn"
                        type="button"
                        onClick={this.cancelVoice}
                      >
                        <span
                          className="fal fa-times"
                          style={{ color: "#9c929d" }}
                        />
                      </button>
                    ) : (
                      <button
                        className="record-btn"
                        type="button"
                        onClick={this.cancelVoice}
                      >
                        <span
                          className="fal fa-trash-alt"
                          style={{ color: "#9c929d" }}
                        />
                      </button>
                    )
                  ) : (
                    <React.Fragment>
                      <label
                        className="file-upload fal fa-paperclip"
                        htmlFor="file-upload"
                        style={{
                          fontFamily: "Font Awesome 5 Pro",
                          margin: "auto",
                          marginBottom: 0,
                        }}
                      >
                        <input
                          className="btn btn-outline-primary"
                          type="file"
                          id="file-upload"
                          onChange={() => {
                            this.upload("file");
                          }}
                          autoFocus={false}
                        />
                      </label>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default (props) => (
  <Context.Consumer>
    {(state) => (
      <Chat
        {...props}
        md={state.md}
        lg={state.lg}
        getContact={state.getContact}
        messageAcknowledge={state.messageAcknowledge}
      />
    )}
  </Context.Consumer>
);
