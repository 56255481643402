import React, { Component } from 'react';
import SupporterImage from '../style/img/supporter_image.png';
import SupporterMessages from './SupporterMessages';
import SupervisorChart from './SupervisorChart';
import Modal from '../components/Modal';
import OfflineSupporter from './OfflineSupporter';
import Audio from '../audio/alert.mp3';

export default class MainChatPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            adminData: this.props.adminData,
            page: null,
            suppervisorChartPage: 'supporter-daily-report'
        };
    }
    selectMenu = (event) => {
        let test = document.querySelectorAll('ul.side-menu>li');
        for (let i = 0; i < test.length; i++) {
            test[i].classList.remove('selected');
        }
        event.target.classList.add('selected');
        this.setState({
            suppervisorChartPage: event.target.getAttribute('id')
        });
    }
    showPage = () => {
        switch (this.props.adminData.supporterType) {
            case 'SUPERVISIOR':
                return (
                    <SupervisorChart page={this.state.suppervisorChartPage} />
                );
            default:
                return (
                    <SupporterMessages
                        message={this.props.message}
                        ws={this.props.ws}
                        connected={this.props.connected}
                    />
                );
        }
    }
    reconnect = () => {
        let syncButton = document.getElementById('sync-btn');
        syncButton.classList.add('sync-animation');
        this.props.socketConnect();
    }

    isOffline = (message) => {
        this.props.handleOffline(message);
    }

    render() {
        const {isOffline} = this.props;
        return (
            <React.Fragment>
                <div
                    className='main-navbar'
                    style={
                        this.props.adminData.supporterType !== 'SUPERVISIOR'
                            ? { paddingRight: '13px' }
                            : { paddingRight: '75px' }
                    }
                >
                    <div>
                        <div className='user-image'>
                            <img src={SupporterImage} alt='User' />
                        </div>
                        <div className='supporter-name'>
                            {this.props.adminData.name}
                        </div>
                        <div
                            className='connection-wrapper'
                            style={{
                                display: this.props.connected ? 'none' : 'flex'
                            }}
                            onClick={this.reconnect}
                        >
                            <div className='connection-status p-3'>
                                اتصال قطع است
                            </div>
                            <div
                                className='connection-status fal fa-sync'
                                id='sync-btn'
                            />
                        </div>
                    </div>
                    <div>
                        <div className="supporter-status">
                            <button type="button" className={isOffline === false || isOffline === "false" ? "status status-online" : "status status-offline"} data-toggle="modal" data-target="#modal">
                                <div className={isOffline === false || isOffline === "false" ? "status-icon check-icon" : "status-icon powerOff-icon"}>
                                    <span className={isOffline === false || isOffline === "false" ? "fal fa-check-circle" : "fal fa-power-off"} />
                                </div>
                                {isOffline === false || isOffline === "false" ? "آنلاین" : "آفلاین"}
                            </button>
                        </div>
                    </div>
                </div>
                {!isOffline ? (
                    <React.Fragment>
                        <div
                    className='content-container rtl'
                    style={
                        this.props.adminData.supporterType !== 'SUPERVISIOR'
                            ? { paddingRight: 0 }
                            : null
                    }
                >
                    <div className='container-fluid h-100'>
                        {this.showPage()}
                    </div>
                </div>
                {this.props.adminData.supporterType === 'SUPERVISIOR' ? (
                    <div className='right-side-menu'>
                        <ul className='side-menu'>
                            <li
                                className='fal fa-user-headset selected'
                                id='supporter-daily-report'
                                onClick={this.selectMenu}
                            ></li>
                            <li
                                className='fal fa-chart-bar'
                                id='message-charts'
                                onClick={this.selectMenu}
                            ></li>
                        </ul>
                    </div>
                ) : null}
                <audio controls={false} id='audio'>
                    <source src={Audio} type='audio/mpeg' />
                </audio>
                    </React.Fragment>
                ): (
                    <OfflineSupporter onClick={this.isOffline} />
                )}
                <Modal submitClick={this.isOffline} />
            </React.Fragment>
        );
    }
}
