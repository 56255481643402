import React, { Component } from 'react';
import SupporterReportCard from '../components/SupporterReportCard';

class DailyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supporterList: []
    };
    this.getAllSupporters = this.getAllSupporters.bind(this);
  }
  componentDidMount() {
    this.getAllSupporters();
  }
  getAllSupporters() {
    const axios = require('axios');
    let data = {
      username: sessionStorage.getItem('user'),
      password: sessionStorage.getItem('tk')
    };
    axios
      .post(process.env.REACT_APP_RINGEL_URL + 'supp/getall/', data, {
        contentType: 'application/json'
      })
      .then(response => {
        console.log(response);
        this.setState({
          supporterList: response.data
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
  render() {
    return (
      <>
        <div className='row' style={{ padding: '30px 20px 0' }}>
          <div className='col-12 report-page-title'>
            عملکرد روزانه ساپورترها
          </div>
        </div>
        <div
          className='row ltr'
          style={{
            padding: '30px 20px',
            'overflow-y': 'scroll',
            'box-sizing': 'border - box',
            'height': 'calc(100% - 51px)'
          }}
        >
          {this.state.supporterList.length !== 0
            ? this.state.supporterList.map((supporter, index) => (
                <SupporterReportCard className='rtl' supporter={supporter} key={index} />
              ))
            : null}
        </div>
      </>
    );
  }
}
export default DailyReport;
