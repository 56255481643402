import React, { Component } from 'react';
import ChatList from './ChatList';
import Chat from './Chat';
import ChatData from './ChatData';
import { Context } from '../App';

class SupporterMessages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedContact: null,
            section: 'chatlist',
            showChatData: false
        };
    }
    componentDidMount() {
        this.getTags();
    }
    changeSection = section => {
        this.setState({
            section: section,
            selectedContact: null
        });
    };
    toggleShowChatData = e => {
        e.preventDefault();
        this.setState(prev => {
            return {
                showChatData: !prev.showChatData
            };
        });
    };
    getTags = () => {
        const axios = require('axios');
        let config = {
            headers: {
                username: sessionStorage.getItem('user'),
                password: sessionStorage.getItem('tk')
            }
        };
        axios
            .get(process.env.REACT_APP_RINGEL_URL + 'tags/', config)
            .then(response => {
                response.data.forEach(el => {
                    this.props.addTag(el);
                });
            })
            .catch(error => {
                console.log('getTags() : ', error);
            });
    };
    setSelectedContact = contact_id => {
        this.setState({
            selectedContact: contact_id,
            section: 'chat'
        });
    };
    render() {
        const { section, showChatData } = this.state;
        const { md, lg } = this.props;
        return (
            <React.Fragment>
                <div className='row h-100'>
                    <div
                        className={`col-lg-3 ${
                            !lg
                                ? showChatData
                                    ? 'chat-data-mobile'
                                    : 'd-none'
                                : ''
                        } h-100 bg-main`}
                    >
                        {this.state.selectedContact !== null ? (
                            <ChatData
                                toggleShowChatData={this.toggleShowChatData}
                                telegram_id={this.state.selectedContact}
                            />
                        ) : null}
                    </div>
                    <div
                        className={`col-lg-6 col-md-8 ${
                            !md
                                ? section === 'chat'
                                    ? 'col-12'
                                    : 'd-none'
                                : ''
                        } h-100 bg-main chat-box ${
                            this.state.selectedContact === null
                                ? 'chat-box-image'
                                : ''
                        }`}
                    >
                        {this.state.selectedContact !== null ? (
                            <Chat
                                message={
                                    this.props.message !== null
                                        ? this.props.message
                                        : null
                                }
                                changeSection={() =>
                                    this.changeSection('chatlist')
                                }
                                toggleShowChatData={this.toggleShowChatData}
                                from={this.state.selectedContact}
                                ws={this.props.ws}
                                connected={this.props.connected}
                            />
                        ) : null}
                    </div>
                    <div
                        className={`col-lg-3 col-md-4 ${
                            !md
                                ? section === 'chatlist'
                                    ? 'col-12'
                                    : 'd-none'
                                : ''
                        } h-100 bg-main`}
                    >
                        <ChatList
                            ws={this.props.ws}
                            setSelectedContact={this.setSelectedContact}
                            message={
                                this.props.message !== null
                                    ? this.props.message
                                    : null
                            }
                            selectedContact={this.state.selectedContact}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default props => (
    <Context.Consumer>
        {state => (
            <SupporterMessages
                {...props}
                md={state.md}
                lg={state.lg}
                addTag={state.addTag}
            />
        )}
    </Context.Consumer>
);
