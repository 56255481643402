import React, { Component } from 'react';

class ShowDetail extends Component {
    state = {  }
    render() { 
        return (
            <div className="health-data-detail" onClick={this.props.onClick}>
                <span className={this.props.showDetail ? "fal fa-chevron-up" : "fal fa-chevron-down"} />
            </div>
        );
    }
}
 
export default ShowDetail;